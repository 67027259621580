/**
 * Plantix vs 농진청 병해충 매핑
 *
 * Plantix의 peat_id+variety 값을 키로
 * 진단 결과의 노출 작물명, 한글 병명, 증상내용,
 * 농진청 API 쿼리 파라미터(작물명, 구분-병, 병원체, 해충, 곤충)을 값으로 가지는 해시테이블입니다.
 *
 * @see https://docs.google.com/spreadsheets/d/1jQQWCiYrGMtBGluyqQs2B9A3a4YbcdiIUpxlps0nNKc/edit#gid=1503449534
 */
type MAPPING_TABLE = {
  [key: string]: {
    /** 작물명 */
    cropName: string;
    /** 한글병명 */
    name: string;
    /** 농진청 구분(NP01:병,NP02:병원체,NP03:해충,NP04:곤충) */
    ncpmsDivCode: "" | "NP01" | "NP02" | "NP03" | "NP04";
    /** 농진청 작물명 */
    ncpmsCropName: string;
  };
};

export enum DISEASE_TYPE {
  /** 구분 형태 - 병 */
  SICK = "NP01",
  /** 구분 형태 - 병원체 */
  VIRUS = "NP02",
  /** 구분 형태 - 해충 */
  INSECT_ENEMY = "NP03",
  /** 구분 형태 - 곤충 */
  INSECT = "NP04",
}

/**
 * PEAT_id의 첫 번째 숫자가 나타내는 카테고리
 */
export const PEAT_TYPE_KOR: { [key: string]: any } = {
  1: "곰팡이",
  2: "바이러스",
  3: "박테리아",
  4: "잡초",
  5: "진드기",
  6: "곤충",
  7: "결핍",
  8: "기타",
  9: "건강",
};

export const NAME_TABLE: MAPPING_TABLE = {
  "100015+ALMOND": {
    cropName: "아몬드",
    name: "세균성구멍병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "복숭아",
  },
  "100143+ALMOND": {
    cropName: "아몬드",
    name: "붉은점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "맥문동",
  },
  "999999+ALMOND": {
    cropName: "아몬드",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100002+APPLE": {
    cropName: "사과",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "사과",
  },
  "100006+APPLE": {
    cropName: "사과",
    name: "검은별무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "사과",
  },
  "100185+APPLE": {
    cropName: "사과",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "사과",
  },
  "600002+APPLE": {
    cropName: "사과",
    name: "조팝나무진딧물",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "사과",
  },
  "600003+APPLE": {
    cropName: "사과",
    name: "대만총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "사과",
  },
  "600005+APPLE": {
    cropName: "사과",
    name: "사과면충",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "사과",
  },
  "999999+APPLE": {
    cropName: "사과",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100002+APRICOT": {
    cropName: "살구",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "살구",
  },
  "100015+APRICOT": {
    cropName: "살구",
    name: "세균성구멍병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "살구",
  },
  "600002+APRICOT": {
    cropName: "살구",
    name: "조팝나무진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600011+APRICOT": {
    cropName: "살구",
    name: "사과둥근나무좀",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700007+APRICOT": {
    cropName: "살구",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+APRICOT": {
    cropName: "살구",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100076+BANANA": {
    cropName: "바나나",
    name: "노란잎줄무늬병균\n검은잎줄무늬병균",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100077+BANANA": {
    cropName: "바나나",
    name: "시가끝썩음병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100078+BANANA": {
    cropName: "바나나",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "망고",
  },
  "100079+BANANA": {
    cropName: "바나나",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "가지",
  },
  "100195+BANANA": {
    cropName: "바나나",
    name: "무름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "고추",
  },
  "100254+BANANA": {
    cropName: "바나나",
    name: "바나나 주근깨",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200018+BANANA": {
    cropName: "바나나",
    name: "줄무늬바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200019+BANANA": {
    cropName: "바나나",
    name: "포엽모자이크바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200020+BANANA": {
    cropName: "바나나",
    name: "바나나다발바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200039+BANANA": {
    cropName: "바나나",
    name: "모자이크병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "오이",
  },
  "600003+BANANA": {
    cropName: "바나나",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600015+BANANA": {
    cropName: "바나나",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600032+BANANA": {
    cropName: "바나나",
    name: "바구미과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600033+BANANA": {
    cropName: "바나나",
    name: "뿌리 천공충",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600046+BANANA": {
    cropName: "바나나",
    name: "담배거세미나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "오이",
  },
  "600126+BANANA": {
    cropName: "바나나",
    name: "풀잠자리",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600172+BANANA": {
    cropName: "바나나",
    name: "흉터딱정벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700002+BANANA": {
    cropName: "바나나",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700006+BANANA": {
    cropName: "바나나",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800080+BANANA": {
    cropName: "바나나",
    name: "슈가 스팟",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+BANANA": {
    cropName: "바나나",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100095+BARLEY": {
    cropName: "보리",
    name: "구름무늬병",
    ncpmsDivCode: "",
    ncpmsCropName: "보리",
  },
  "100096+BARLEY": {
    cropName: "보리",
    name: "그물무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100098+BARLEY": {
    cropName: "보리",
    name: "점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "작두콩",
  },
  "100214+BARLEY": {
    cropName: "보리",
    name: "좀녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100356+BARLEY": {
    cropName: "보리",
    name: "속깜부기병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "999999+BARLEY": {
    cropName: "보리",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "보리",
  },
  "100031+BEAN": {
    cropName: "콩",
    name: "검은점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100032+BEAN": {
    cropName: "콩",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "200004+BEAN": {
    cropName: "콩",
    name: "콩황화일반모자이크바이러스",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "500004+BEAN": {
    cropName: "콩",
    name: "점박이응애",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "콩",
  },
  "600002+BEAN": {
    cropName: "콩",
    name: "콩진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "콩",
  },
  "600015+BEAN": {
    cropName: "콩",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+BEAN": {
    cropName: "콩",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700006+BEAN": {
    cropName: "콩",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+BEAN": {
    cropName: "콩",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+BEAN": {
    cropName: "콩",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100002+BITTER_GOURD": {
    cropName: "여주",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "여주",
  },
  "600018+BITTER_GOURD": {
    cropName: "여주",
    name: "큰이십팔점박이무당벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "여주",
  },
  "999999+BITTER_GOURD": {
    cropName: "여주",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100037+CABBAGE": {
    cropName: "양배추",
    name: "검은무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "양배추",
  },
  "300006+CABBAGE": {
    cropName: "양배추",
    name: "검은썩음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "배추",
  },
  "600002+CABBAGE": {
    cropName: "양배추",
    name: "양배추가루진딧물",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "양배추",
  },
  "600022+CABBAGE": {
    cropName: "양배추",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600028+CABBAGE": {
    cropName: "양배추",
    name: "배추흰나비",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600046+CABBAGE": {
    cropName: "양배추",
    name: "담배거세미나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "배추",
  },
  "999999+CABBAGE": {
    cropName: "양배추",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100207+CANOLA": {
    cropName: "유채",
    name: "검은뿌리썩음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "유채",
  },
  "600142+CANOLA": {
    cropName: "유채",
    name: "카놀라 딱정벌레\n꽃가루 딱정벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+CANOLA": {
    cropName: "유채",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+CARROT": {
    cropName: "당근",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100037+CAULIFLOWER": {
    cropName: "브로콜리(녹색꽃양배추)",
    name: "검은무늬병 (黑斑病,Alternaria spot, Black spot)",
    ncpmsDivCode: "",
    ncpmsCropName: "브로콜리(녹색꽃양배추)",
  },
  "300060+CAULIFLOWER": {
    cropName: "브로콜리(녹색꽃양배추)",
    name: "무름병",
    ncpmsDivCode: "",
    ncpmsCropName: "브로콜리(녹색꽃양배추)",
  },
  "999999+CAULIFLOWER": {
    cropName: "브로콜리(녹색꽃양배추)",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100015+CHERRY": {
    cropName: "체리",
    name: "세균성구멍병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "자두",
  },
  "600002+CHERRY": {
    cropName: "체리",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "999999+CHERRY": {
    cropName: "체리",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100034+CHICKPEA": {
    cropName: "병아리콩",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "가지",
  },
  "100118+CHICKPEA": {
    cropName: "병아리콩",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "600289+CHICKPEA": {
    cropName: "병아리콩",
    name: "왕담배나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "감귤",
  },
  "700005+CHICKPEA": {
    cropName: "병아리콩",
    name: "인산 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+CHICKPEA": {
    cropName: "병아리콩",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100122+CITRUS": {
    cropName: "감귤",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100124+CITRUS": {
    cropName: "감귤",
    name: "더뎅이병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100125+CITRUS": {
    cropName: "감귤",
    name: "누른무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100129+CITRUS": {
    cropName: "감귤",
    name: "수지병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100192+CITRUS": {
    cropName: "감귤",
    name: "그을음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100274+CITRUS": {
    cropName: "감귤",
    name: "푸른곰팡이병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "300020+CITRUS": {
    cropName: "감귤",
    name: "궤양병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "500018+CITRUS": {
    cropName: "감귤",
    name: "귤녹응애",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+CITRUS": {
    cropName: "감귤",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600061+CITRUS": {
    cropName: "감귤",
    name: "이세리아깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600122+CITRUS": {
    cropName: "감귤",
    name: "귤굴나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "감귤",
  },
  "600152+CITRUS": {
    cropName: "감귤",
    name: "볼록총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "감귤",
  },
  "600157+CITRUS": {
    cropName: "감귤",
    name: "검은 감귤 진딧물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600158+CITRUS": {
    cropName: "감귤",
    name: "검은점깍지벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600234+CITRUS": {
    cropName: "감귤",
    name: "배나무이",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "배",
  },
  "600235+CITRUS": {
    cropName: "감귤",
    name: "호랑나비",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "감귤",
  },
  "600237+CITRUS": {
    cropName: "감귤",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600251+CITRUS": {
    cropName: "감귤",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600368+CITRUS": {
    cropName: "감귤",
    name: "화살깍지벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "감귤",
  },
  "700004+CITRUS": {
    cropName: "감귤",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700006+CITRUS": {
    cropName: "감귤",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+CITRUS": {
    cropName: "감귤",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700048+CITRUS": {
    cropName: "감귤",
    name: "아연 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800004+CITRUS": {
    cropName: "감귤",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800090+CITRUS": {
    cropName: "감귤",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900008+CITRUS": {
    cropName: "감귤",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+CITRUS": {
    cropName: "감귤",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "감귤",
  },
  "100192+COFFEE": {
    cropName: "커피",
    name: "그을음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100328+COFFEE": {
    cropName: "커피",
    name: "갈색둥근무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "가지",
  },
  "100360+COFFEE": {
    cropName: "커피",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "600367+COFFEE": {
    cropName: "커피",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+COFFEE": {
    cropName: "커피",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100022+COTTON": {
    cropName: "목화, 면화",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "거베라",
  },
  "100034+COTTON": {
    cropName: "목화, 면화",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "가지",
  },
  "100104+COTTON": {
    cropName: "목화, 면화",
    name: "검은무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "무",
  },
  "100106+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100192+COTTON": {
    cropName: "목화, 면화",
    name: "그을음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "200047+COTTON": {
    cropName: "목화, 면화",
    name: "줄무늬바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600002+COTTON": {
    cropName: "목화, 면화",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+COTTON": {
    cropName: "목화, 면화",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600015+COTTON": {
    cropName: "목화, 면화",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+COTTON": {
    cropName: "목화, 면화",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+COTTON": {
    cropName: "목화, 면화",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600046+COTTON": {
    cropName: "목화, 면화",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600161+COTTON": {
    cropName: "목화, 면화",
    name: "분홍솜벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600173+COTTON": {
    cropName: "목화, 면화",
    name: "매미충과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600199+COTTON": {
    cropName: "목화, 면화",
    name: "별노린재과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600227+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600258+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600273+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600289+COTTON": {
    cropName: "목화, 면화",
    name: "Helicoverpa armigera",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600373+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700002+COTTON": {
    cropName: "목화, 면화",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700005+COTTON": {
    cropName: "목화, 면화",
    name: "인산 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700006+COTTON": {
    cropName: "목화, 면화",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+COTTON": {
    cropName: "목화, 면화",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700048+COTTON": {
    cropName: "목화, 면화",
    name: "아연 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800061+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800107+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900013+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900018+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900019+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900027+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900033+COTTON": {
    cropName: "목화, 면화",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+COTTON": {
    cropName: "목화, 면화",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100002+CUCUMBER": {
    cropName: "오이",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "오이",
  },
  "100264+CUCUMBER": {
    cropName: "오이",
    name: "노균병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "오이",
  },
  "200006+CUCUMBER": {
    cropName: "오이",
    name: "모자이크병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "오이",
  },
  "200007+CUCUMBER": {
    cropName: "오이",
    name: "오이녹반모자이크바이러스",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "오이",
  },
  "500004+CUCUMBER": {
    cropName: "오이",
    name: "잎응애",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600002+CUCUMBER": {
    cropName: "오이",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+CUCUMBER": {
    cropName: "오이",
    name: "오이총채벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600015+CUCUMBER": {
    cropName: "오이",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+CUCUMBER": {
    cropName: "오이",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600290+CUCUMBER": {
    cropName: "오이",
    name: "붉은호박딱정벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700002+CUCUMBER": {
    cropName: "오이",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700004+CUCUMBER": {
    cropName: "오이",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800107+CUCUMBER": {
    cropName: "오이",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+CUCUMBER": {
    cropName: "오이",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600174+CURRANT": {
    cropName: "코린트",
    name: "노린재류",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+CURRANT": {
    cropName: "코린트",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100108+EGGPLANT": {
    cropName: "가지",
    name: "갈색둥근무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "가지",
  },
  "100276+EGGPLANT": {
    cropName: "가지",
    name: "갈색무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "가지",
  },
  "200037+EGGPLANT": {
    cropName: "가지",
    name: "모자이크병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "고추",
  },
  "300055+EGGPLANT": {
    cropName: "가지",
    name: "파이토플라즈마",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "여주",
  },
  "500004+EGGPLANT": {
    cropName: "가지",
    name: "잎응애",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600002+EGGPLANT": {
    cropName: "가지",
    name: "진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+EGGPLANT": {
    cropName: "가지",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600015+EGGPLANT": {
    cropName: "가지",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600018+EGGPLANT": {
    cropName: "가지",
    name: "큰이십팔점박이무당벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+EGGPLANT": {
    cropName: "가지",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600020+EGGPLANT": {
    cropName: "가지",
    name: "좁은가슴잎벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+EGGPLANT": {
    cropName: "가지",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600036+EGGPLANT": {
    cropName: "가지",
    name: "네눈쑥가지나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600046+EGGPLANT": {
    cropName: "가지",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600089+EGGPLANT": {
    cropName: "가지",
    name: "매미과",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "600111+EGGPLANT": {
    cropName: "가지",
    name: "진딧물",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "600179+EGGPLANT": {
    cropName: "가지",
    name: "나방과",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "600273+EGGPLANT": {
    cropName: "가지",
    name: "바구미과",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "600373+EGGPLANT": {
    cropName: "가지",
    name: "나방과",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "700004+EGGPLANT": {
    cropName: "가지",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "700006+EGGPLANT": {
    cropName: "가지",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "700007+EGGPLANT": {
    cropName: "가지",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "999999+EGGPLANT": {
    cropName: "가지",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "가지",
  },
  "100357+GINGER": {
    cropName: "생강",
    name: "흰별무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "생강",
  },
  "100365+GINGER": {
    cropName: "생강",
    name: "근경썩음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "생강",
  },
  "600039+GINGER": {
    cropName: "생강",
    name: "열대거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700007+GINGER": {
    cropName: "생강",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "생강",
  },
  "100002+GRAM": {
    cropName: "녹두",
    name: "흰가루병",
    ncpmsDivCode: "",
    ncpmsCropName: "녹두",
  },
  "100067+GRAM": {
    cropName: "녹두",
    name: "점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100177+GRAM": {
    cropName: "녹두",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감자",
  },
  "200014+GRAM": {
    cropName: "녹두",
    name: "모자이크바이러스",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "글라디올러스",
  },
  "200034+GRAM": {
    cropName: "녹두",
    name: "잎주름병",
    ncpmsDivCode: "",
    ncpmsCropName: "녹두",
  },
  "600002+GRAM": {
    cropName: "녹두",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+GRAM": {
    cropName: "녹두",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600022+GRAM": {
    cropName: "녹두",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600046+GRAM": {
    cropName: "녹두",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "999999+GRAM": {
    cropName: "녹두",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "녹두",
  },
  "100208+GRAPE": {
    cropName: "포도",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "포도",
  },
  "500014+GRAPE": {
    cropName: "포도",
    name: "포도혹응애",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600003+GRAPE": {
    cropName: "포도",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600019+GRAPE": {
    cropName: "포도",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600089+GRAPE": {
    cropName: "포도",
    name: "매미과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+GRAPE": {
    cropName: "포도",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "포도",
  },
  "800033+GRAPE": {
    cropName: "포도",
    name: "노균병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "포도",
  },
  "999999+GRAPE": {
    cropName: "포도",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100192+GUAVA": {
    cropName: "구아바",
    name: "그을음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100371+GUAVA": {
    cropName: "구아바",
    name: "궤양병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감귤",
  },
  "100372+GUAVA": {
    cropName: "구아바",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600015+GUAVA": {
    cropName: "구아바",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+GUAVA": {
    cropName: "구아바",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700005+GUAVA": {
    cropName: "구아바",
    name: "인산 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+GUAVA": {
    cropName: "구아바",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100038+MAIZE": {
    cropName: "옥수수",
    name: "깜부기병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100051+MAIZE": {
    cropName: "옥수수",
    name: "깜부기병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100065+MAIZE": {
    cropName: "옥수수",
    name: "잎마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "마늘",
  },
  "100082+MAIZE": {
    cropName: "옥수수",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "100100+MAIZE": {
    cropName: "옥수수",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "100107+MAIZE": {
    cropName: "옥수수",
    name: "잿빛무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "비파",
  },
  "100160+MAIZE": {
    cropName: "옥수수",
    name: "점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100161+MAIZE": {
    cropName: "옥수수",
    name: "옥수수 깨씨무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100167+MAIZE": {
    cropName: "옥수수",
    name: "이삭썩음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100176+MAIZE": {
    cropName: "옥수수",
    name: "맥류 잎집눈무늬병",
    ncpmsDivCode: "",
    ncpmsCropName: "옥수수",
  },
  "100236+MAIZE": {
    cropName: "옥수수",
    name: "갈색점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100283+MAIZE": {
    cropName: "옥수수",
    name: "잎집무늬마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100297+MAIZE": {
    cropName: "옥수수",
    name: "노균병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "100318+MAIZE": {
    cropName: "옥수수",
    name: "흑문병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200023+MAIZE": {
    cropName: "옥수수",
    name: "옥수수줄무늬바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "300002+MAIZE": {
    cropName: "옥수수",
    name: "세균점무늬병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "",
  },
  "300034+MAIZE": {
    cropName: "옥수수",
    name: "시들음병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "300035+MAIZE": {
    cropName: "옥수수",
    name: "옥수수 세균 줄기썩음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "600002+MAIZE": {
    cropName: "옥수수",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+MAIZE": {
    cropName: "옥수수",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600039+MAIZE": {
    cropName: "옥수수",
    name: "담배거세미나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "오이",
  },
  "700002+MAIZE": {
    cropName: "옥수수",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700004+MAIZE": {
    cropName: "옥수수",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700005+MAIZE": {
    cropName: "옥수수",
    name: "인산 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700006+MAIZE": {
    cropName: "옥수수",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800107+MAIZE": {
    cropName: "옥수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800110+MAIZE": {
    cropName: "옥수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+MAIZE": {
    cropName: "옥수수",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "옥수수",
  },
  "100069+MANGO": {
    cropName: "망고",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "사과",
  },
  "100192+MANGO": {
    cropName: "망고",
    name: "그을음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100329+MANGO": {
    cropName: "망고",
    name: "기형",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "300013+MANGO": {
    cropName: "망고",
    name: "궤양병",
    ncpmsDivCode: "",
    ncpmsCropName: "망고",
  },
  "500024+MANGO": {
    cropName: "망고",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600003+MANGO": {
    cropName: "망고",
    name: "볼록총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "망고",
  },
  "600019+MANGO": {
    cropName: "망고",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600061+MANGO": {
    cropName: "망고",
    name: "캘리포니아감귤깍지벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600169+MANGO": {
    cropName: "망고",
    name: "잎귤나방과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600297+MANGO": {
    cropName: "망고",
    name: "날개끝검은집명나방",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600298+MANGO": {
    cropName: "망고",
    name: "베짜기개미",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600301+MANGO": {
    cropName: "망고",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700002+MANGO": {
    cropName: "망고",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+MANGO": {
    cropName: "망고",
    name: "철분결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800004+MANGO": {
    cropName: "망고",
    name: "녹조류",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+MANGO": {
    cropName: "망고",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200042+MANIOC": {
    cropName: "카사바",
    name: "카사바모자이크바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "500004+MANIOC": {
    cropName: "카사바",
    name: "잎응애",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800042+MANIOC": {
    cropName: "카사바",
    name: "갈색무늬병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+MANIOC": {
    cropName: "카사바",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100002+MELON": {
    cropName: "멜론",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "사과",
  },
  "100264+MELON": {
    cropName: "멜론",
    name: "노균병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200006+MELON": {
    cropName: "멜론",
    name: "오이모자이크바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600002+MELON": {
    cropName: "멜론",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600018+MELON": {
    cropName: "멜론",
    name: "큰이십팔점박이무당벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600022+MELON": {
    cropName: "멜론",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+MELON": {
    cropName: "멜론",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100004+MILLET": {
    cropName: "기장",
    name: "노균병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "조",
  },
  "100054+MILLET": {
    cropName: "기장",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100089+MILLET": {
    cropName: "기장",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "600013+MILLET": {
    cropName: "기장",
    name: "기장머리나방",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+MILLET": {
    cropName: "기장",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900037+NONPLANT": {
    cropName: "",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100002+OKRA": {
    cropName: "오크라",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "오크라",
  },
  "100335+OKRA": {
    cropName: "오크라",
    name: "점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "오크라",
  },
  "200065+OKRA": {
    cropName: "오크라",
    name: "모자이크병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "",
  },
  "500004+OKRA": {
    cropName: "오크라",
    name: "응애과",
    ncpmsDivCode: "",
    ncpmsCropName: "오크라",
  },
  "600002+OKRA": {
    cropName: "오크라",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+OKRA": {
    cropName: "오크라",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600020+OKRA": {
    cropName: "오크라",
    name: "좁은가슴잎벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "오크라",
  },
  "600022+OKRA": {
    cropName: "오크라",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600089+OKRA": {
    cropName: "오크라",
    name: "매미충과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+OKRA": {
    cropName: "오크라",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100142+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100145+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "300023+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "500009+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600070+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600071+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600072+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600073+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600075+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600178+OLIVE": {
    cropName: "올리브",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800089+OLIVE": {
    cropName: "올리브",
    name: "탄저병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+OLIVE": {
    cropName: "올리브",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100003+ONION": {
    cropName: "양파",
    name: "노균병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "무",
  },
  "100212+ONION": {
    cropName: "양파",
    name: "잎마름병",
    ncpmsDivCode: "",
    ncpmsCropName: "양파",
  },
  "100280+ONION": {
    cropName: "양파",
    name: "잎마름병",
    ncpmsDivCode: "",
    ncpmsCropName: "양파",
  },
  "600003+ONION": {
    cropName: "양파",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600022+ONION": {
    cropName: "양파",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "999999+ONION": {
    cropName: "양파",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100070+PAPAYA": {
    cropName: "파파야",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200015+PAPAYA": {
    cropName: "파파야",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200016+PAPAYA": {
    cropName: "파파야",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200017+PAPAYA": {
    cropName: "파파야",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600019+PAPAYA": {
    cropName: "파파야",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "999999+PAPAYA": {
    cropName: "파파야",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100033+PEA": {
    cropName: "파파야",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "600022+PEA": {
    cropName: "파파야",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+PEA": {
    cropName: "파파야",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100014+PEACH": {
    cropName: "복숭아",
    name: "잎오갈병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "복숭아",
  },
  "100015+PEACH": {
    cropName: "복숭아",
    name: "세균성구멍병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "복숭아",
  },
  "600002+PEACH": {
    cropName: "복숭아",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "999999+PEACH": {
    cropName: "복숭아",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100056+PEANUT": {
    cropName: "땅콩",
    name: "검은무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "100057+PEANUT": {
    cropName: "땅콩",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "100132+PEANUT": {
    cropName: "땅콩",
    name: "밑둥썩음병/지제부 부패",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "상추",
  },
  "100184+PEANUT": {
    cropName: "땅콩",
    name: "점무늬낙엽병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "사과",
  },
  "200012+PEANUT": {
    cropName: "땅콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "200077+PEANUT": {
    cropName: "땅콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "500004+PEANUT": {
    cropName: "땅콩",
    name: "잎응애",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "600002+PEANUT": {
    cropName: "땅콩",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+PEANUT": {
    cropName: "땅콩",
    name: "총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "가지",
  },
  "600046+PEANUT": {
    cropName: "땅콩",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600089+PEANUT": {
    cropName: "땅콩",
    name: "매미충과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600246+PEANUT": {
    cropName: "땅콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "600289+PEANUT": {
    cropName: "땅콩",
    name: "왕담배나방",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "700006+PEANUT": {
    cropName: "땅콩",
    name: "질소결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "700007+PEANUT": {
    cropName: "땅콩",
    name: "철분결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "700009+PEANUT": {
    cropName: "땅콩",
    name: "망간결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "999999+PEANUT": {
    cropName: "땅콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "땅콩",
  },
  "100009+PEAR": {
    cropName: "완두",
    name: "녹병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+PEAR": {
    cropName: "완두",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "완두",
  },
  "100034+PEPPER": {
    cropName: "고추",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "고추",
  },
  "100081+PEPPER": {
    cropName: "고추",
    name: "잿빛곰팡이병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "고추",
  },
  "100085+PEPPER": {
    cropName: "고추",
    name: "갈색점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "고추",
  },
  "100156+PEPPER": {
    cropName: "고추",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "고추",
  },
  "100192+PEPPER": {
    cropName: "고추",
    name: "그을음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100293+PEPPER": {
    cropName: "고추",
    name: "흰가루병",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "100296+PEPPER": {
    cropName: "고추",
    name: "곰팡이류",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "200052+PEPPER": {
    cropName: "고추",
    name: "잎오갈병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "복숭아",
  },
  "200055+PEPPER": {
    cropName: "고추",
    name: "모자이크",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "고추",
  },
  "200061+PEPPER": {
    cropName: "고추",
    name: "모자이크병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "고추",
  },
  "300003+PEPPER": {
    cropName: "고추",
    name: "세균성점무늬병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "고추",
  },
  "500011+PEPPER": {
    cropName: "고추",
    name: "차먼지응애",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600002+PEPPER": {
    cropName: "고추",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600015+PEPPER": {
    cropName: "고추",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+PEPPER": {
    cropName: "고추",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+PEPPER": {
    cropName: "고추",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "600046+PEPPER": {
    cropName: "고추",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600136+PEPPER": {
    cropName: "고추",
    name: "볼록총채벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600289+PEPPER": {
    cropName: "고추",
    name: "왕담배나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "땅콩",
  },
  "700001+PEPPER": {
    cropName: "고추",
    name: "칼슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "700004+PEPPER": {
    cropName: "고추",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "700006+PEPPER": {
    cropName: "고추",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "700007+PEPPER": {
    cropName: "고추",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "999999+PEPPER": {
    cropName: "고추",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "고추",
  },
  "200024+PIGEONPEA": {
    cropName: "비둘기콩",
    name: "모자이크병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600002+PIGEONPEA": {
    cropName: "비둘기콩",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600054+PIGEONPEA": {
    cropName: "비둘기콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600063+PIGEONPEA": {
    cropName: "비둘기콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600180+PIGEONPEA": {
    cropName: "비둘기콩",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+PIGEONPEA": {
    cropName: "비둘기콩",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100258+PISTACHIO": {
    cropName: "피스타치오",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100261+PISTACHIO": {
    cropName: "피스타치오",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600165+PISTACHIO": {
    cropName: "피스타치오",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "800078+PISTACHIO": {
    cropName: "피스타치오",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+PISTACHIO": {
    cropName: "피스타치오",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100202+POMEGRANATE": {
    cropName: "석류나무",
    name: "점무늬병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "석류나무",
  },
  "100204+POMEGRANATE": {
    cropName: "석류나무",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "석류나무",
  },
  "300058+POMEGRANATE": {
    cropName: "석류나무",
    name: "역병",
    ncpmsDivCode: "",
    ncpmsCropName: "석류나무",
  },
  "600002+POMEGRANATE": {
    cropName: "석류나무",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600003+POMEGRANATE": {
    cropName: "석류나무",
    name: "총채벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600296+POMEGRANATE": {
    cropName: "석류나무",
    name: "구아바블루",
    ncpmsDivCode: "",
    ncpmsCropName: "석류나무",
  },
  "700007+POMEGRANATE": {
    cropName: "석류나무",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "석류나무",
  },
  "999999+POMEGRANATE": {
    cropName: "석류나무",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "석류나무",
  },
  "100040+POTATO": {
    cropName: "감자",
    name: "잎마름병",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "100043+POTATO": {
    cropName: "감자",
    name: "검은무늬썩음병",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "100044+POTATO": {
    cropName: "감자",
    name: "은무늬병",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "100121+POTATO": {
    cropName: "감자",
    name: "갈색무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "제라늄",
  },
  "100321+POTATO": {
    cropName: "감자",
    name: "겹둥근무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감자",
  },
  "200008+POTATO": {
    cropName: "감자",
    name: "감자잎말이바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200009+POTATO": {
    cropName: "감자",
    name: "감자바이러스Y",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "200061+POTATO": {
    cropName: "감자",
    name: "알파파모자이크바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "200072+POTATO": {
    cropName: "감자",
    name: "감자몹톱바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "300009+POTATO": {
    cropName: "감자",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "600002+POTATO": {
    cropName: "감자",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600020+POTATO": {
    cropName: "감자",
    name: "좁은가슴잎벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+POTATO": {
    cropName: "감자",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600117+POTATO": {
    cropName: "감자",
    name: "담배나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700002+POTATO": {
    cropName: "감자",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "999999+POTATO": {
    cropName: "감자",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "감자",
  },
  "100002+PUMPKIN": {
    cropName: "호박",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "쥬키니호박",
  },
  "600290+PUMPKIN": {
    cropName: "호박",
    name: "붉은호박딱정벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "호박",
  },
  "999999+PUMPKIN": {
    cropName: "호박",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "호박",
  },
  "100058+RICE": {
    cropName: "논벼",
    name: "잎도열병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100064+RICE": {
    cropName: "논벼",
    name: "깨씨무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100080+RICE": {
    cropName: "논벼",
    name: "잎집무늬마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100162+RICE": {
    cropName: "논벼",
    name: "이삭누룩병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100163+RICE": {
    cropName: "논벼",
    name: "잎집썩음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100179+RICE": {
    cropName: "논벼",
    name: "좀공균핵병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100182+RICE": {
    cropName: "논벼",
    name: "갈색잎마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "100183+RICE": {
    cropName: "논벼",
    name: "갈색점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "옥수수",
  },
  "300014+RICE": {
    cropName: "논벼",
    name: "흰잎마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "300032+RICE": {
    cropName: "논벼",
    name: "세균성벼알마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "논벼",
  },
  "600019+RICE": {
    cropName: "논벼",
    name: "가루깍지벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "600046+RICE": {
    cropName: "논벼",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600093+RICE": {
    cropName: "논벼",
    name: "황색줄기전공충",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "600097+RICE": {
    cropName: "논벼",
    name: "쌀벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "600098+RICE": {
    cropName: "논벼",
    name: "벼잎벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "논벼",
  },
  "600104+RICE": {
    cropName: "논벼",
    name: "벼멸구",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "논벼",
  },
  "600105+RICE": {
    cropName: "논벼",
    name: "나비목 명나방과",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600108+RICE": {
    cropName: "논벼",
    name: "끝동매미충",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600137+RICE": {
    cropName: "논벼",
    name: "벼이삭애벌래",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700002+RICE": {
    cropName: "논벼",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "700004+RICE": {
    cropName: "논벼",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "700006+RICE": {
    cropName: "논벼",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "700048+RICE": {
    cropName: "논벼",
    name: "아연 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "800060+RICE": {
    cropName: "논벼",
    name: "먹나비",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "800061+RICE": {
    cropName: "논벼",
    name: "벼메뚜기",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "논벼",
  },
  "999999+RICE": {
    cropName: "논벼",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "논벼",
  },
  "100113+ROSE": {
    cropName: "장미",
    name: "검은무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "장미",
  },
  "600002+ROSE": {
    cropName: "장미",
    name: "감자수염진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "999999+ROSE": {
    cropName: "장미",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "장미",
  },
  "100084+SORGHUM": {
    cropName: "수수",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "수수",
  },
  "600002+SORGHUM": {
    cropName: "수수",
    name: "기장테두리진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600039+SORGHUM": {
    cropName: "수수",
    name: "열대거세미나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "옥수수",
  },
  "999999+SORGHUM": {
    cropName: "수수",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "수수",
  },
  "100102+SOYBEAN": {
    cropName: "콩",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100103+SOYBEAN": {
    cropName: "콩",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100109+SOYBEAN": {
    cropName: "콩",
    name: "잎마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "토마토",
  },
  "100110+SOYBEAN": {
    cropName: "콩",
    name: "균핵병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100112+SOYBEAN": {
    cropName: "콩",
    name: "콩갈색무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100168+SOYBEAN": {
    cropName: "콩",
    name: "콩점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100170+SOYBEAN": {
    cropName: "콩",
    name: "자주무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100171+SOYBEAN": {
    cropName: "콩",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "100221+SOYBEAN": {
    cropName: "콩",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "녹두",
  },
  "100289+SOYBEAN": {
    cropName: "콩",
    name: "노균병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "200014+SOYBEAN": {
    cropName: "콩",
    name: "누른모자이크병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "300030+SOYBEAN": {
    cropName: "콩",
    name: "세균성점무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "콩",
  },
  "500004+SOYBEAN": {
    cropName: "콩",
    name: "점박이응애",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "콩",
  },
  "600022+SOYBEAN": {
    cropName: "콩",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600039+SOYBEAN": {
    cropName: "콩",
    name: "파밤나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "콩",
  },
  "600041+SOYBEAN": {
    cropName: "콩",
    name: "대두자벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "600046+SOYBEAN": {
    cropName: "콩",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600141+SOYBEAN": {
    cropName: "콩",
    name: "흰별수중다리밤나방",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "600273+SOYBEAN": {
    cropName: "콩",
    name: "바구미",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "600289+SOYBEAN": {
    cropName: "콩",
    name: "왕담배나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700002+SOYBEAN": {
    cropName: "콩",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "700007+SOYBEAN": {
    cropName: "콩",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "700049+SOYBEAN": {
    cropName: "콩",
    name: "붕소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "999999+SOYBEAN": {
    cropName: "콩",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "콩",
  },
  "300011+STRAWBERRY": {
    cropName: "딸기",
    name: "세균모무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "딸기",
  },
  "700006+STRAWBERRY": {
    cropName: "딸기",
    name: "질소결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "딸기",
  },
  "700007+STRAWBERRY": {
    cropName: "딸기",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "딸기",
  },
  "999999+STRAWBERRY": {
    cropName: "딸기",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "딸기",
  },
  "100238+SUGARBEET": {
    cropName: "사탕무",
    name: "사탕무 갈반병균",
    ncpmsDivCode: "",
    ncpmsCropName: "사탕무",
  },
  "999999+SUGARBEET": {
    cropName: "사탕무",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "사탕무",
  },
  "100052+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100063+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100153+SUGARCANE": {
    cropName: "사탕수수",
    name: "녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "땅콩",
  },
  "100159+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100337+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100348+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100377+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "200031+SUGARCANE": {
    cropName: "사탕수수",
    name: "모자이크병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "300027+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "500023+SUGARCANE": {
    cropName: "사탕수수",
    name: "잎응애",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600005+SUGARCANE": {
    cropName: "사탕수수",
    name: "사과면충",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "사과",
  },
  "600015+SUGARCANE": {
    cropName: "사탕수수",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+SUGARCANE": {
    cropName: "사탕수수",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600086+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600231+SUGARCANE": {
    cropName: "사탕수수",
    name: "사탕수수 덤불",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600344+SUGARCANE": {
    cropName: "사탕수수",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700002+SUGARCANE": {
    cropName: "사탕수수",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700049+SUGARCANE": {
    cropName: "사탕수수",
    name: "붕소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "900034+SUGARCANE": {
    cropName: "사탕수수",
    name: "줄무늬 병",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+SUGARCANE": {
    cropName: "사탕수수",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100121+TOBACCO": {
    cropName: "담배",
    name: "갈색무늬병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "제라늄",
  },
  "100378+TOBACCO": {
    cropName: "담배",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "담배",
  },
  "200037+TOBACCO": {
    cropName: "담배",
    name: "모자이크병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "200078+TOBACCO": {
    cropName: "담배",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "담배",
  },
  "600002+TOBACCO": {
    cropName: "담배",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600020+TOBACCO": {
    cropName: "담배",
    name: "좁은가슴잎벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600088+TOBACCO": {
    cropName: "담배",
    name: "줄무늬오이잎벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "담배",
  },
  "999999+TOBACCO": {
    cropName: "담배",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "담배",
  },
  "100002+TOMATO": {
    cropName: "토마토",
    name: "흰가루병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "토마토",
  },
  "100034+TOMATO": {
    cropName: "토마토",
    name: "시들음병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "토마토",
  },
  "100046+TOMATO": {
    cropName: "토마토",
    name: "잎마름역병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "100081+TOMATO": {
    cropName: "토마토",
    name: "잿빛곰팡이병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "100257+TOMATO": {
    cropName: "토마토",
    name: "잎곰팡이",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "100321+TOMATO": {
    cropName: "토마토",
    name: "겹무늬병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "200035+TOMATO": {
    cropName: "토마토",
    name: "반점위조바이러스",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "200036+TOMATO": {
    cropName: "토마토",
    name: "황화잎말림바이러스",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "200037+TOMATO": {
    cropName: "토마토",
    name: "모자이크병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "토마토",
  },
  "300028+TOMATO": {
    cropName: "토마토",
    name: "풋마름병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "토마토",
  },
  "300050+TOMATO": {
    cropName: "토마토",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "500004+TOMATO": {
    cropName: "토마토",
    name: "점박이응애",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "토마토",
  },
  "600002+TOMATO": {
    cropName: "토마토",
    name: "목화진딧물",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "토마토",
  },
  "600003+TOMATO": {
    cropName: "토마토",
    name: "꽃노랑총채벌레",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "토마토",
  },
  "600015+TOMATO": {
    cropName: "토마토",
    name: "흰가루이",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600019+TOMATO": {
    cropName: "토마토",
    name: "가루깍지벌레",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600022+TOMATO": {
    cropName: "토마토",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600038+TOMATO": {
    cropName: "토마토",
    name: "뿔나방",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "600046+TOMATO": {
    cropName: "토마토",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600289+TOMATO": {
    cropName: "토마토",
    name: "왕담배나방",
    ncpmsDivCode: "NP03",
    ncpmsCropName: "토마토",
  },
  "700001+TOMATO": {
    cropName: "토마토",
    name: "칼슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "700002+TOMATO": {
    cropName: "토마토",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "700004+TOMATO": {
    cropName: "토마토",
    name: "마그네슘 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "700005+TOMATO": {
    cropName: "토마토",
    name: "인산 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "700006+TOMATO": {
    cropName: "토마토",
    name: "질소 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "700007+TOMATO": {
    cropName: "토마토",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "800102+TOMATO": {
    cropName: "토마토",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "800103+TOMATO": {
    cropName: "토마토",
    name: "황화잎말림바이러스",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "800112+TOMATO": {
    cropName: "토마토",
    name: "글리포세이트 손상",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "900015+TOMATO": {
    cropName: "토마토",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "900016+TOMATO": {
    cropName: "토마토",
    name: "쪼개진 토마토",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "900027+TOMATO": {
    cropName: "토마토",
    name: "제초제 피해",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "999999+TOMATO": {
    cropName: "토마토",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "토마토",
  },
  "100322+TURMERIC": {
    cropName: "강황",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100323+TURMERIC": {
    cropName: "강황",
    name: "탄저병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "감자",
  },
  "100375+TURMERIC": {
    cropName: "강황",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "600046+TURMERIC": {
    cropName: "강황",
    name: "담배거세미나방",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "700002+TURMERIC": {
    cropName: "강황",
    name: "칼륨 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+TURMERIC": {
    cropName: "강황",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700009+TURMERIC": {
    cropName: "강황",
    name: "망간 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+TURMERIC": {
    cropName: "강황",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100059+WHEAT": {
    cropName: "밀",
    name: "붉은녹병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "보리",
  },
  "100061+WHEAT": {
    cropName: "밀",
    name: "겉깜부기병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "보리",
  },
  "100062+WHEAT": {
    cropName: "밀",
    name: "줄기녹병",
    ncpmsDivCode: "NP01",
    ncpmsCropName: "밀",
  },
  "100092+WHEAT": {
    cropName: "밀",
    name: "",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "100093+WHEAT": {
    cropName: "밀",
    name: "붉은곰팡이병",
    ncpmsDivCode: "",
    ncpmsCropName: "밀",
  },
  "100097+WHEAT": {
    cropName: "밀",
    name: "밀황갈색반점병균",
    ncpmsDivCode: "",
    ncpmsCropName: "밀",
  },
  "100220+WHEAT": {
    cropName: "밀",
    name: "흰가루병",
    ncpmsDivCode: "NP02",
    ncpmsCropName: "보리",
  },
  "600002+WHEAT": {
    cropName: "밀",
    name: "목화진딧물",
    ncpmsDivCode: "NP04",
    ncpmsCropName: "",
  },
  "600037+WHEAT": {
    cropName: "밀",
    name: "노란다리긴가슴잎벌레",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "700007+WHEAT": {
    cropName: "밀",
    name: "철분 결핍",
    ncpmsDivCode: "",
    ncpmsCropName: "밀",
  },
  "800109+WHEAT": {
    cropName: "밀",
    name: "세포손상",
    ncpmsDivCode: "",
    ncpmsCropName: "",
  },
  "999999+WHEAT": {
    cropName: "밀",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "밀",
  },
  "100002+ZUCCHINI": {
    cropName: "쥬키니호박",
    name: "흰가루병",
    ncpmsDivCode: "",
    ncpmsCropName: "쥬키니호박",
  },
  "600022+ZUCCHINI": {
    cropName: "쥬키니호박",
    name: "아메리카잎굴파리",
    ncpmsDivCode: "",
    ncpmsCropName: "쥬키니호박",
  },
  "999999+ZUCCHINI": {
    cropName: "쥬키니호박",
    name: "건강한 식물",
    ncpmsDivCode: "",
    ncpmsCropName: "쥬키니호박",
  },
};

/**
 * Plantix peat_id에 따른 증상내용(=요약) 한글 번역
 */
type MAPPING_DESCRIPTION = {
  [key: string]: {
    pathogen: string;
    description: string[];
  };
};

export const DESCRIPTION_TABLE: MAPPING_DESCRIPTION = {
  "100002": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기나 때로는 과실에 하얀 반점이 있습니다.",
      "- 잎의 윗면 또는 밑면에 흰색 가루가 덮여 있습니다.",
      "- 성장이 저해됩니다.",
      "- 잎이 시들거나 떨어집니다.",
    ],
  },
  "100003": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 노란 반점이 갈색 반점으로 괴사한다.  ",
      "- 잎의 아랫부분에 흰색에서 회색 솜털이 나 있다. ",
      "- 고엽제",
      "- 성장이 저하된다. ",
      "- 어린 새싹, 꽃, 열매가 시들어버린다.",
    ],
  },
  "100004": {
    pathogen: "곰팡이",
    description: [
      "- 꽃의 단면은 잎이 무성한 구조를 보여줍니다. ",
      "- 잎 뒷면은 곰팡이가 자랍니다. ",
      "- 잎에 노란색 반점이 생깁니다. ",
      "- 더이상 분지하지 않는다.",
    ],
  },
  "100006": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 녹색 또는 갈색 반점이 있습니다. ",
      "- 나중에 큰 갈색 반점이 생깁니다. ",
      "- 잎이 변형되서나 조기 탈락합니다. ",
      "- 열매에 짙은 갈색 원형이 생기고, 부풀어 올라 단단해집니다.",
    ],
  },
  "100009": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 주황색-빨간색의 원형 반점이 나타납니다. ",
      "- 잎 뒷면에 갈색을 띠는 담즙 모양의 파생물이 관찰됩니다. ",
      "- 때때로 나뭇가지와 어린 줄기에 궤양이 발생한다.",
    ],
  },
  "100014": {
    pathogen: "곰팡이",
    description: ["- 잎이 변형되고, 붉은색으로 변색됩니다. ", "- 잎에 곰팡이가 생깁니다. ", "- 잎이 조기 탈락합니다."],
  },
  "100015": {
    pathogen: "곰팡이",
    description: ["- 작은 갈색 또는 자줏빛 반점이 생깁니다. ", "- 반점이 커지면서 밝은 갈색으로 변합니다."],
  },
  "100022": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 가장자리에서 황변이 먼저 나타납니다. ",
      "- 주요 잎맥은 녹색을 유지합니다. ",
      "- 줄기에 검은 줄무늬가 나타납니다. ",
      "- 식물이 시듭니다.",
    ],
  },
  "100031": {
    pathogen: "곰팡이",
    description: [
      "- 잎과 줄기에 둥글고 짙은 갈색-검은색 함몰된 반점이 생깁니다.",
      "- 잎맥과 잎자루에 갈색-검은색 병변(상처부위)이 나타납니다. ",
      "- 옅은 갈색-녹슨 갈색의 원형 병변으로, 꼬투리와 줄기에 검은 테두리를 둘러싸고 나타납니다.",
    ],
  },
  "100032": {
    pathogen: "곰팡이",
    description: [
      "- 오래된 잎의 뒷면에 갈색-노란색 작은 농포가 나타납니다. ",
      "- 노란색 구멍으로 둘러싸여 있습니다. ",
      "- 증상은 잎자루, 줄기, 꼬투리에도 나타날 수 있습니다. ",
      "- 잎이 누렇게 변하거나 시듭니다.",
    ],
  },
  "100033": {
    pathogen: "곰팡이",
    description: ["- 잎과 줄기에 갈색 포자가 나타납니다. ", "- 잎이 변형됩니다. ", "- 식물의 성장을 감소시킵니다."],
  },
  "100034": {
    pathogen: "곰팡이",
    description: ["- 식물이 시듭니다. ", "- 잎이 노랗게 변합니다. ", "- 줄기 안쪽이 갈색 혹은 붉은색으로 나타납니다."],
  },
  "100037": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 황색 원형이 있는 회색빛-갈색 반점이 나타납니다.",
      "- 잎의 중심이 얇아져, ",
      "- 잎이 시들고 훼손되어 떨어집니다. ",
      "- 감염된 종자에서 자라는 묘목을 약화시킵니다.",
    ],
  },
  "100038": {
    pathogen: "곰팡이",
    description: [
      "- 묘목 단계에서 발육을 방해하고, 꽃 또는 이삭이 생성되지 않을 수 있습니다.  ",
      "- 오래된 식물의 경우 이삭에 검은 반점의 종양을 형성합니다. ",
      "- 파열될 때, 가루같은 검은색 내용물이 나타납니다. ",
      "- 잎의 종양은 보통 작게 남아있고 파열되지 않고 건조됩니다.",
    ],
  },
  "100040": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 끝이나 가장자리에 짙은 갈색 반점이 나타납니다. ",
      "- 반점은 투명한 상처로 변합니다. ",
      "- 잎이 시들고 죽습니다. ",
      "- 감자 덩이줄기에 회색-푸른 반점이 나타납니다.",
    ],
  },
  "100043": {
    pathogen: "곰팡이",
    description: [
      "- 감자 표면에 단단하고 솟아오른 검은 반점이 생깁니다. ",
      "- 윗 뿌리와 새싹에 흰 곰팡이 혹은 갈색의 움푹 파인 반점이 생깁니다. ",
      "- 잎이 시들고 변색됩니다.",
    ],
  },
  "100044": {
    pathogen: "곰팡이",
    description: [
      "- 원형 은빛-회색 반점이 나타납니다. ",
      "- 반점의 모양은 불규칙적이고 감자 품종에 따라 다릅니다. ",
      "- 반점은 보통 표면에 남아있어, 아래 조직에 손상을 주지 않습니다.",
    ],
  },
  "100046": {
    pathogen: "곰팡이",
    description: [
      "- 갈색 반점이 잎의 넓은 부분까지 덮히게 됩니다. ",
      "- 잎 뒷면이 흰색 곰팡이로 뒤덮힙니다. ",
      "- 과일에 회색 또는 갈색 주름이 나타납니다. ",
      "- 과육이 단단해지고 부패합니다.",
    ],
  },
  "100051": {
    pathogen: "곰팡이",
    description: [
      "- 꽃차례는 부분적 또는 전체적으로 검은 가루 형태의 공팡이로 덮여 있습니다. ",
      "- 특이한 구조의 잎은 이삭과 꽃부분에 나타난다. ",
      "- 영향을 받은 이삭은 둥글거나 물방울 모양이고, 검은 가루 덩어리로 채워져 있습니다. ",
      "- 엉킨 잎맥 가닥 사이에 포자 덩어리가 섞여 있습니다. ",
      "- 이삭에 실크 또는 알맹이가 없다.",
    ],
  },
  "100052": {
    pathogen: "곰팡이",
    description: [
      "- 줄기와 잎에 붉은 반점이 나타납니다. ",
      "- 곡물이 변색되고 썩습니다. ",
      "- 잎이 시들고 쪼그라 듭니다. ",
      "- 시큼한 냄새가 납니다.",
    ],
  },
  "100054": {
    pathogen: "곰팡이",
    description: [
      "- 이삭의 머리부분에 분홍색-빨간색으로 물든 액체(꿀이슬)가 분비됩니다. ",
      "- 검은색 곰팡이 덩어리가 곡물 알갱이를 대체합니다.",
    ],
  },
  "100056": {
    pathogen: "곰팡이",
    description: [
      "- (초기) 노란 후광이 있는 연한 갈색 반점이 생긴다. ",
      "- (후기) 짙은 반점 ",
      "- 반점이 진해지고 커집니다. ",
      "- (초기) 잎 윗부분에 은빛의 털 같은 곰팡이가 자란다. ",
      "- 고엽제는 줄기와 생산성을 약화시킨다.",
    ],
  },
  "100057": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 밑면에 노란 후광으로 둘러싼 녹슨 농포가 나타납니다. ",
      "- 양쪽이 농포로 뒤덮이면 심각한 감염입니다. ",
      "- 잎이 노랗게 물들고 오그라듭니다.",
    ],
  },
  "100058": {
    pathogen: "곰팡이",
    description: [
      "- 둥근 형태의 가장자리가 어두운  흰색 반점이 나타납니다. ",
      "- 식물 마디에도 증상이 나타날 수 있습니다. ",
      "- 묘목이나 어린 식물은 죽습니다.",
    ],
  },
  "100059": {
    pathogen: "곰팡이",
    description: [
      "- 잎사귀에 작은 주황-갈색 농포가 나타납니다. ",
      "- 노란색 후광이 농포 주위에 나타날 수 있습니다. ",
      "- 잎 껍질에도 영향을 미칠 수 있습니다.",
    ],
  },
  "100061": {
    pathogen: "곰팡이",
    description: [
      "- 개화 직전이나 개화 단계에서 나타납니다. ",
      '- 검은 알갱이가 가루같은 검은 머리쪽에 "죽은 생선" 냄새가 난다. ',
      "- 발달 중인 알맹이는 곰팡이 증식으로 대체된다.",
    ],
  },
  "100062": {
    pathogen: "곰팡이",
    description: ["- 작고 녹슨 농포가 줄무늬로 배열되어 나타납니다. ", "- 줄기와 머리쪽도 영향을 받을 수 있습니다."],
  },
  "100063": {
    pathogen: "곰팡이",
    description: [
      "- 검은 채찍 같은 구조가 식물에서 자랍니다. ",
      "- 식물의 성장을 방해합니다 .",
      "- 잎이 얇고 뻣뻣해집니다.",
    ],
  },
  "100064": {
    pathogen: "곰팡이",
    description: [
      "- 어린 잎사귀에 원형 갈색 괴사 반점이 나타난다. ",
      "- 식물이 성숙해지면서 붉은 가장자리 반점이 생긴다. ",
      "- 줄기와 잎이 노랗게 변하고 시듭니다.",
    ],
  },
  "100065": {
    pathogen: "곰팡이",
    description: ["- 증상은 아래 잎에서 먼저 나타나며, ", "- 이 질병으로 인해 잎은 마르고 시들어 죽습니다."],
  },
  "100067": {
    pathogen: "곰팡이",
    description: [
      "- 작은 연갈색 고리 반점이 있으며, 잎의 가장자리가 적갈색으로 둘러싸여 있습니다.  ",
      "- 나뭇가지에 얼룩이 있고 녹색 꼬투리가 있습니다. ",
      "- 고엽현상이 발생하고 수확량 손실을 조래합니다.",
    ],
  },
  "100069": {
    pathogen: "곰팡이",
    description: ["- 잎, 꽃, 과일에 하얀 가루 모양의 반점이 있습니다.", "- 잎과 과일이 변형됩니다."],
  },
  "100070": {
    pathogen: "곰팡이",
    description: [
      "- 잎 밑면에 물에 젖은 반점이 나타납니다. ",
      "- 이후 가루로 된 자국이 생깁니다. ",
      "- 반점이 확대되어 잎을 덮습니다. ",
      "- 잎과 엽록소가 변형됩니다.",
      "- 잎이 조기 탈락하게 됩니다.",
    ],
  },
  "100076": {
    pathogen: "곰팡이",
    description: [
      "- 초기, 잎에 갈색 반점이 나타납니다. ",
      "- 입맥과 평행한 노란색 후광이 있는 좁고 어두운 줄무늬가 나타납니다. ",
      "- 확대되면 괴사합니다.",
    ],
  },
  "100077": {
    pathogen: "곰팡이",
    description: [
      "- 과일의 끝부분이 건조하고 회색-검은색으로 부패합니다. ",
      "- 곰팡이가 과일 전체를 덮을 수 있다. ",
      "- 모양이 이상하다.",
    ],
  },
  "100078": {
    pathogen: "곰팡이",
    description: [
      "- 과일의 껍질에 짙은 갈색-검은색의 움푹 파인 반점을 나타낸다. ",
      "- 추후 더 큰 반점으로 발전합니다. ",
      "- 주황색-분홍색 진균이 중앙까지 자랍니다. ",
      "- 과일이 일찍 익고 썩습니다.",
    ],
  },
  "100079": {
    pathogen: "곰팡이",
    description: [
      "- 잎이 노랗게 변하고 시듭니다. ",
      "- 잎이 갈색으로 변하고 탈락하게 됩니다. ",
      "- 줄기가 갈라집니다. ",
      "- 줄기에 노란색-붉은색 줄무늬가 나타납니다. ",
      "- 결국 식물의 모든 부위가 썩어 죽게됩니다.",
    ],
  },
  "100080": {
    pathogen: "곰팡이",
    description: [
      "- 줄기에 타원형이고 회색의 물에 젖은 병변이 나타납니다. ",
      "- 잎과 줄기에 불규칙한 갈색 테두리가 있는 회색-흰색 병변이 나타납니다.",
    ],
  },
  "100081": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 싹, 과일, 꼬투리에 회색 곰팡이가 핍니다. ",
      "- 조직에 수많은 털과 회갈색 곰팡이가 발달합니다. ",
      "- 영향받는 부분이 시들고 갈변, 감소 됩니다. ",
      "- 나뭇가지가 물러집니다. ",
      "- 나뭇가지에 동고병이 생깁니다.",
    ],
  },
  "100082": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 반점이 작은 황갈색으로 발달하면서 병변이 나타난다. ",
      "- 위 윗면과 아랫면의 농포가 짙은 갈색에서 검은색으로 변한다.",
    ],
  },
  "100084": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 작고 타원형의 물에 젖은 병변이 나타납니다. ",
      "- 가운데가 갈색이고 테두리가 보라색인 반점이 나타납니다. ",
      "- 검은 얼룩들이 잎의 전체를 덮습니다. ",
      "- 줄기가 썩습니다.",
    ],
  },
  "100085": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 커다란 원형 갈색반점, 가운데는 흰색고리와 노란색 후광이 나타납니다,  ",
      "- 반점이 확대되어 큰 병변이 됩니다. ",
      "- 나뭇잎이 노랗게 변하고 잎이 떨어지며, 과일이 햇볕에 노출됩니다.",
    ],
  },
  "100089": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 노란색-흰색의 반점이 나타납니다,. ",
      "- 노란색 태두리가 있는 붉은 빛이 도는 오랜지색 농포가 나타납니다.",
    ],
  },
  "100092": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 연한 회색-진한 갈색 얼룩이 나타납니다. ",
      "- 반점 안에 검은 열매체가 심한 경우 잎 전체가 병변의 영향을 받게 됩니다.",
    ],
  },
  "100093": {
    pathogen: "곰팡이",
    description: [
      "- 이 질병은 모종 병, 머리 병 두 가지 증상으로 특징 지워집니다. ",
      "- 줄기 밑 부분의 연한 갈색, 물에 젖은 병변, 화분증의 썩음, 병번은 모종 병의 징후입니다. ",
      "- 물에 젖은 이삭의 꽃, 표백된 짚은 머리 병의 두가지 특징입니다. ",
      "- 따뜻하고 습한 날씨에는 진균이 풍부하게 발전하여 분홍색-연한강색으로 변합니다.",
    ],
  },
  "100095": {
    pathogen: "곰팡이",
    description: [
      "- 엽록소, 불규칙 또는 다이아몬드 모양으로 병변이 오래된 잎에 퍼집니다. ",
      "- 병변의 중심은 말라가지만, 가장자리는 갈색으로 변합니다. ",
      "- 병변은 잎의 넓은 영역을 덮기 위해 확대되고 합쳐집니다.",
    ],
  },
  "100096": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 갈색 병변이 그물 모양의 패턴을 만들게 됩니다. ",
      "- 병변은 잎을 따라 자라며, 노란 후광으로 둘러싸여 있습니다. ",
      "- 작은 갈색 줄무늬가 점액 위에 있습니다. ",
      "- 씨앗이 오그라듭니다.",
    ],
  },
  "100097": {
    pathogen: "곰팡이",
    description: [
      "- 잎에  노란 후광, 황갈색 병변이 나타납니다. ",
      "- 잎 끝에서 나머지 부분으로 방변이 확장됩니다. ",
      "- 핑크색 또는 붉은색-검은색 알갱이가 생깁니다.",
    ],
  },
  "100098": {
    pathogen: "곰팡이",
    description: [
      "- 잎날과 칼집에 갈색 직사각형 반점이 나타납니다. ",
      "- 추후 반점이 합쳐질 수 있고, 넓은 부분 또는 조직이 괴사할 수 있습니다. ",
      "- 녹색 잎 조직의 손실은 조기 부패와 생산량 손실을 초래할 수 있습니다.",
    ],
  },
  "100100": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 작고 붉은 오랜지색 가루 모양의 농포가 밀집하여 나타납니다. ",
      "- 잎에도 노란색과 갈색 반점이 나타납니다. ",
      "- 줄기가 썩고 곡물 품질이 나빠집니다.",
    ],
  },
  "100102": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 밑면과 정맥을 따라 작고 회색 반점이 나타납니다. ",
      "- 반점 주변은 노란색으로 변색됩니다. ",
      "- 보통 식물의 개화 단계에 잎 밑면에서 흔하게 감염됩니다.",
    ],
  },
  "100103": {
    pathogen: "곰팡이",
    description: [
      "- 꼬투리와 줄기에 갈색 반점이 나타납니다. ",
      "- 정맥이 갈색으로 변색됩니다. ",
      "- 잎이 곱슬해집니다.",
      "- 씨앗이 눅눅해집니다.",
    ],
  },
  "100104": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 보라색 테두리가 있는 갈색 반점이 나타납니다. ",
      "- 반점 중심부가 마르고 빠질 수 있습니다. ",
      "- 줄기에 움푹 들어간 작은 반점이 있습니다. ",
      "- 꽃봉오리가 떨어집니다.",
    ],
  },
  "100106": {
    pathogen: "곰팡이",
    description: [
      "- 작고 연한 녹색-노란색 각진 반점이 나타납니다. ",
      "- 반점 아래 희끄무레한 회색 가루로 자랍니다. ",
      "- 작은 반점이 서로 합쳐서 큰 반점으로 형성됩니다. ",
      "- 잎은 마르고 일찍 탈락하여, 벌거벗은 가지만 남습니다.",
    ],
  },
  "100107": {
    pathogen: "곰팡이",
    description: [
      "- 노란색 테두리로 둘러싸인 작고 뾰족한 병변이 나타납니다. ",
      "- 잎 전체에 병변이 합쳐지고 흐려질 수 있습니다.",
    ],
  },
  "100108": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 작고 둥근 노란 반점이 약간 움푹 패여 나타납니다. ",
      "- 반점들은 확대되고 합쳐지고 노란색 후광과 함께 갈색으로 변합니다.",
      "- 납엽이 곱슬말려가면서 탈락하게됩니다. ",
      "- 생산 수율이 감소하게 됩니다.",
    ],
  },
  "100109": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 불규칙한 적갈색 병변이 나타납니다. ",
      "- 연한 녹색-노란색 테두리를 가진 병변이 나타납니다. ",
      "- 더 큰 병변은 밝은 또는 어두운 테두리가 있습니다.",
    ],
  },
  "100110": {
    pathogen: "곰팡이",
    description: [
      "- 과일, 잎, 잎자루에 반점이 나타납니다.",
      "- 하얀 솜 같은 곰팡이로 뒤덮인 반점입니다. ",
      "- 추후 구조물과 같은 어두운 사마귀가 나타납니다. ",
      "- 중기 및 상부 식물 부분이 시듭니다.",
    ],
  },
  "100112": {
    pathogen: "곰팡이",
    description: [
      "- 오래된 잎에 노란색 테두리가 있는 적갈색 반점이 나타납니다. ",
      "- 반점이 합쳐져 노란색 테두리로 둘러싸인 큰 갈색 영역을 형성할 수 있습니다. ",
      "- 잎 전체가 녹슨 갈색과 노란색으로 변하고 일찍 탈락하게 됩니다.",
    ],
  },
  "100113": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 작은 반점이 나타납니다. ",
      "- 노란색 테두리로 둘러싸입니다. . ",
      "- 잎이 일찍 탈락합니다.",
    ],
  },
  "100118": {
    pathogen: "곰팡이",
    description: ["- 둥글고 갈색의 가루같은 농포가 나타납니다. ", "- 잎의 양쪽에 농포가 발생합니다."],
  },
  "100121": {
    pathogen: "곰팡이",
    description: [
      "- 노란색 테두리가 있는 갈색-검은색 반점이 나타난다. ",
      "- 나중에 불규칙하거나 둥글게 변화하여 부서지기 쉽게 변한다. ",
      "- 미성숙한 과일 위에 노란색 테두리가 있는 어두운 반점은 코르크 같은 조직으로 형성됩니다.",
    ],
  },
  "100122": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 연한 황갈색 반점이 나타납니다. ",
      "- 반점 중앙이 회색으로 변합니다. ",
      "- 과일에 작고 단단하고 건조한 갈색-검은색 반점이 나타납니다.",
    ],
  },
  "100124": {
    pathogen: "곰팡이",
    description: [
      "- 밝은 색의 농포가 나타납니다. ",
      "- 나중에 갈색 윗부분이 있는 원뿔형으로 변형됩니다. ",
      "- 잎의 가장자리가 너덜너덜하고 뒤틀리고 구겨집니다. ",
      "- 과일, 잔가지, 새싹, 줄기 등에도 영향을 미칩니다. ",
      "- 성장이 정체되고 무성하게 됩니다.",
    ],
  },
  "100125": {
    pathogen: "곰팡이",
    description: [
      "- 윗잎 표면에 노란색 반점이 생기고, 아랫잎 표면에 병변이 생기게 됩니다. ",
      "- 병변은 시간이 지나면서 어두워지고 검은색으로 변합니다. ",
      "- 영향을 받은 나무는 잎이 점차 탈락하고 과일 수확량이 감소합니다.",
    ],
  },
  "100129": {
    pathogen: "곰팡이",
    description: [
      "- 어두운 물이 나와 나무껍질을 물에 젖게 합니다. ",
      "- 건조한 날씨에 나무껍질 틈에서 수액이 나옵니다. ",
      "- 아래부분의 나무껍질은 물에 젖어 있고, 끈저끈적하며 색은 적갈색-검은색입니다. ",
      "- 괴사 부위는 내부조직까지 확장되어 나무 껍질을 둘러싸고 붕괴될 수 있습니다.",
    ],
  },
  "100132": {
    pathogen: "곰팡이",
    description: [
      "- 줄기와 주변 지면에 어둡고 둥근 구조를 가진 푹신한 흰색 매트가 나타납니다., ",
      "- 잎이 시들게됩니다. ",
      "- 식물이 굳거나 죽습니다.",
    ],
  },
  "100142": {
    pathogen: "곰팡이",
    description: [
      "- 엽록소 부위로, 잎의 윗면이 갈색으로 변하고 괴사합니다. ",
      "- 곰팡이가 증식하여 잎 아랫면이 납회색으로 변합니다. ",
      "- 잎이 탈락하여 발육을 방해하고 기형적인 열매를 맺게 합니다.",
    ],
  },
  "100143": {
    pathogen: "곰팡이",
    description: [
      "- 연두색-누르스름한 오렌지색의 짙은 불규칙한 반점이 갈색 후광으로 둘러싸여 있습니다.",
      "- 잎의 끝이나 가장자리가 말리고 마릅니다. ",
      "- 잎이 조기에 탈락할 수 있습니다.",
    ],
  },
  "100145": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 검고 그을린 반점이 점차 나타납니다. ",
      "- 반점 주변에 노란색 테두리가 생깁니다. ",
      "- 조기 탈락, 잔가지와 꽃망울이 죽을 수 있습니다.",
    ],
  },
  "100153": {
    pathogen: "곰팡이",
    description: [
      "- 초기 증상은 길쭉한 노란 잎 반점입니다. ",
      "- 반점은 점차 적갈색으로 변하게 됩니다. ",
      "- 영향이 심할 경우 잎이 괴사하게 됩니다.",
    ],
  },
  "100156": {
    pathogen: "곰팡이",
    description: [
      "- 과일의 병변",
      "- 잎과 줄기에 짙은 가장자리의 회갈색 반점이 나타납니다. ",
      "- 원형 고리가 과일 반점안에 나타납니다. ",
      "- 가지와 과일이 부패하여 죽습니다.",
    ],
  },
  "100159": {
    pathogen: "곰팡이",
    description: [
      "- 초기 잎 양쪽에 붉은 반점이 있습니다. ",
      "- 추후 붉은색에서 갈색 테두리를 가진 타원형 병변이 나타납니다.",
    ],
  },
  "100160": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 윗면에 작고 흩어진 연두색-노란색의 엽록색 반점이 나타납니다. ",
      "- 이러한 반점은 중심이 표백되어 건조되고 진한 갈색의 불규칙한 테두리가 있는 원형 또는 타원형 병변으로 발전합니다. ",
      "- 심한 경우 잎 전체가 합쳐져서 시들게 됩니다. ",
      "- Phaeosphaeria Leaf Spot은 일반적으로 중요하지 않은 늦은 계절의 질병으로 여겨집니다.",
    ],
  },
  "100161": {
    pathogen: "곰팡이",
    description: [
      "- 다이아몬드 모양의 가늘고 길쭉한 황갈색 병변으로, 잎의 아래 면은 갈색으로 변합니다. ",
      "- 병변은 잎맥을 넘어 확장됩니다.",
      "- 병변이 합쳐져 잎이 병충해로 뒤덮인다. ",
      "- 질병 후기에는 회색으로 뒤덮이고 기형이 발생한다.",
    ],
  },
  "100162": {
    pathogen: "곰팡이",
    description: [
      "- 쌀알 위에 작고 부드러운 오랜지색의 공이 올려져 있다. ",
      "- 공은 말라서 녹색을 띈 검은색으로 변합ㄴ;ㅣ다. ",
      "- 곡물이 변색되고 무게 감소, 발아율이 감소합니다.",
    ],
  },
  "100163": {
    pathogen: "곰팡이",
    description: [
      "- 최상단 잎사귀에 반점과 같은 병변이 나타납니다. ",
      "- 잎의 껍질이 변색됩니다. ",
      "- 변색된 알맹이와 하얀 가루같은 곰팡이가 자랍니다.",
    ],
  },
  "100167": {
    pathogen: "곰팡이",
    description: [
      "- 알맹이는 흰색, 분홍색 곰팡이가 나타납니다. ",
      "- 황갈색 줄무늬의 방사형 패턴이 있습니다. ",
      "- 이삭은 시들고 알맹이는 썩습니다. ",
      "- 식용이 불가합니다.",
    ],
  },
  "100168": {
    pathogen: "곰팡이",
    description: [
      "- 잎사귀에 물에 젖은 작은 반점이 나타납니다. ",
      "- 중심부가 회색이고 가장자리가 짙은 갈색인 괴사성 둥근 병변으로 자랍니다.",
      "- 병변이 줄기와 꼬투리로 퍼질 수 있습니다.",
      "- 씨앗은 검은 반점을 보이며 오그라든 것처럼 보입니다.",
    ],
  },
  "100170": {
    pathogen: "곰팡이",
    description: [
      "- 윗잎에 자줏빛-갈색으로 얼룩진 변색이 나타납니다. ",
      "- 적갈색 반점은 줄기와 꼬투리에도 나타날 수 있습니다. ",
      "- 감염된 씨앗은 분홍색에서 보라색 변색이 일어날 수 있습니다. ",
      "- 발아율 및 모종 출현에 영향을 미칠 수 있습니다.",
    ],
  },
  "100171": {
    pathogen: "곰팡이",
    description: [
      "- 잎맥 사이에 노란반점이 흩어져 있습니다. ",
      "- 질병이 진행되면 잎이 마르거나 탈락할 수 있습니다. ",
      "- 줄기와 뿌리가 갈색으로 변색됩니다. ",
      "- 꽃이 시들 수 있습니다.",
    ],
  },
  "100176": {
    pathogen: "곰팡이",
    description: [
      "- 잎사귀에 물에 젖은 자국이 있습니다. ",
      "- 병변은 어두운 여백을 가진 황갈색 안점으로 발전합니다. ",
      "- 시들고 반점이 합쳐집니다.",
    ],
  },
  "100177": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기, 꽃잎 또는 꼬투리에 작고 불규칙한 물에 젖은 얼룩이 나타납니다. ",
      "- 합병된 점은 어두운 중심과 밝은 여백으로 움푹 들어간 병변이 됩니다. ",
      "- 줄기와 꽃잎에 동고병이 나타납니다.",
    ],
  },
  "100179": {
    pathogen: "곰팡이",
    description: [
      "- 잎껍질에 작고 불규칙한 검은 병변이 나타납니다. ",
      "- 잎사귀 사이에 있는 줄기부분이 썩어서 무너집니다. ",
      "- 원추형 크기가 감소하고 알갱이가 감소합니다.",
    ],
  },
  "100182": {
    pathogen: "곰팡이",
    description: [
      "- 잎 끝에서부터 가볍고 물에 젖은 손상이 발생합니다. ",
      "- 나중에 병변이 확대되어 잎이 갈라집니다. ",
      "- 잎이 시듭니다.",
    ],
  },
  "100183": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 선형 병변이 나타납니다. ",
      "- 잎집이 점진적으로 변색됩니다. ",
      "- 알맹이가 너무 빨리 읽습니다. ",
      "- 씨앗과 곡물이 자주색-갈색으로 변색됩니다.",
    ],
  },
  "100184": {
    pathogen: "곰팡이",
    description: [
      "- 노란 테두리가 있는 작은 갈색 반점이 나타납니다. ",
      "- 병변은 잎의 중간까지 연장됩니다. ",
      "- 잎은 안쪽으로 둥글게 말려 부러지기 쉬워집니다. ",
      "- 고엽제",
    ],
  },
  "100185": {
    pathogen: "곰팡이",
    description: [
      "- 나무껍질에 작고 둥근 붉은색-보라색 반점이 나타납니다. ",
      "- 위쪽 가장자리가 동고병이 발달됩니다. ",
      "- 중심부에 크림색의 하얀 곰팡이가 자라고 있습니다. ",
      "- 과일과 잎에 갈색반점이 있습니다.",
    ],
  },
  "100192": {
    pathogen: "곰팡이",
    description: [
      "- 과일에 짙은 회색-검은색 곰팡이가 생깁니다. ",
      "- 잎, 잔가지, 중기에도 영향을 미칩니다. ",
      "- 잎이 죽거나 탈락할 수 있습니다.",
    ],
  },
  "100195": {
    pathogen: "곰팡이",
    description: [
      "- 뿌리 내부 조직이 부패합니다. ",
      "- 악취가 진동합니다. ",
      "- 잎의 목부분과 잎 밑부분에 갈색-노란색 물로 젖습니다. ",
      "- 몸통 밑부분이 붓고 갈라집니다. ",
      "- 잎이 건조하게 됩니다.",
    ],
  },
  "100202": {
    pathogen: "곰팡이",
    description: [
      "- 꽃받침과 잎에 어둡고 작은 반점이 나타납니다. ",
      "- 반점은 확대되고 진하게 변합니다. ",
      "- 잎의 반점은 노란색 테두리를 가지고 있습니다. ",
      "- 잎은 연두색, 노락색으로 변하고 탈락하게됩니다. ",
      "- 잔가지도 감염될 수 있습니다.",
    ],
  },
  "100204": {
    pathogen: "곰팡이",
    description: [
      "- 노란 테두리가 있는 검은 반점이 잎 전체에 나타납니다. ",
      "-과일이 건조해지고 갈색-검은색으로 변색됩니다. ",
      "- 씨앗 꼬투리가 썩습니다. ",
      "- 나뭇가지에 고동병이 발생한다. ",
      "- 뿌리가 겹쳐, 잎마름병에 걸리게 됩니다.",
    ],
  },
  "100207": {
    pathogen: "곰팡이",
    description: ["검색안됨"],
  },
  "100208": {
    pathogen: "곰팡이",
    description: [
      "- 어린 잎 가장자리 근처에 노란 반점이 나타납니다. ",
      "- 반점에 회색-흰색 가루 형태의 진균이 성장합니다. ",
      "- 혈관과 새싹에 갈색-검은색 반점이 있습니다. ",
      "- 과일은 짙은 갈색으로 변하여, 흉터가 생길 수 있습니다.",
    ],
  },
  "100212": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 작고 흰 긴 반점이 나타나며, 종종 연두색 테두리가 생긴다. ",
      "- 시간이 지나면서, 얼룩은 움푹 패이고 짚빛 색이 되며, 중앙에는 가늘고 긴 칼집이 생깁니다. ",
      "- 잎이 퇴화하면서 식물이 죽을 수도 있습니다. ",
      "- 들판에서 죽어가는 식물의 커다란 노란 반점을 관찰할 수 있습니다.",
    ],
  },
  "100214": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 작고 주황색-갈색 농포가 나타납니다. ",
      "- 옅은 테두리로 둘러싸여 있는 농포",
      "- 잎 밑면에는 검은 농포가 나타납니다.",
    ],
  },
  "100220": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기, 이삭에 하얗고 솜털 같은 반점이 나타납니다. ",
      "- 일부 작물에서는, 반점이 크고 돌출된 농포처럼 보일 수 있습니다. ",
      "- 이 질병이 진행되면서 회색으로 변하게 됩니다. ",
      "- 질병 후반에는 흰 반점 사이에 눈에 띄는 검은 반점이 나타날 수 있습니다.",
    ],
  },
  "100221": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기, 과일에 흰 반점이 나타납니다. ",
      "- 잎 윗, 아랫면에 흰색 곰팡이가 자라나 성장을 저해합니다. ",
      "- 나뭇잎이 오그라들고 탈락하게 됩니다.",
    ],
  },
  "100236": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기, 칼집, 껍질에 노란색-갈색의 반점이 나타납니다. ",
      "- 병든 조직의 띠가 잎의 많이 부분을 덮을 수 있습니다. ",
      "- 주 정맥 또는 바로 가까이 짙은 갈색-검은색 반점이 있습니다.",
    ],
  },
  "100238": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기, 꽃잎에 적갈색 테두리의 옅은 갈색 또는 회색 둥근 반점이 나타난다. ",
      "- 반점들이 합쳐지고, 잎들이 갈색으로 변하고, 둥글게 말려 죽을 수 도 있습니다.",
    ],
  },
  "100254": {
    pathogen: "곰팡이",
    description: [
      "- 잎과 열매에 작고 짙은 갈색-검은 반점이 나타납니다. ",
      "- 반점은 일렬로 군집할 수 있으며, 중간 잎맥, 주맥, 포엽에도 나타날 수 있다.",
    ],
  },
  "100257": {
    pathogen: "곰팡이",
    description: [
      "- 잎 윗면에 연한 녹색 또는 황색의 확산 반점이 나타납니다. ",
      "- 아랫 잎에 초록색-회색빛 보라색 반점이 나타납니다. ",
      "- 잎이 마르고 말립니다.",
    ],
  },
  "100258": {
    pathogen: "곰팡이",
    description: [
      "- 잎 양쪽에 건조한 갈색 반점이 나타납니다. ",
      "- 잎날이 창백하고 갈색을 띄며, 잎맥으로 점차 확장됩니다. ",
      "- 잎이 시들어 조기 탈락하게 됩니다.",
    ],
  },
  "100261": {
    pathogen: "곰팡이",
    description: [
      "- 중간 잎맥이 검고, 짙은 갈색의 가루모양의 반점이 나타난다. ",
      "- 잎이 시들어 조기 탈락하게 됩니다. ",
      "- 과일에 붉은 테두리로 둘러싸인 살색 원형의 괴사 반점이 생깁니다. ",
      "- 점점 커지면서 곰팡이가 생기기 시작합니다.",
    ],
  },
  "100264": {
    pathogen: "곰팡이",
    description: [
      "- 윗잎 표면에 노란색 반점이 나타납니다. ",
      "- 잎 아래쪽이 부드럽고 흐릿한 회색으로 성장합니다. ",
      "- 어린 새싹, 꽃 또는 과일을 왜소하게 만들거나 죽입니다. ",
      "- 성장을 저해시킵니다.",
    ],
  },
  "100274": {
    pathogen: "곰팡이",
    description: [
      "- 과일 껍질이 물에 젖은 듯 부드러운 부위가 나타납니다. ",
      "- 백색 곰팡이가 발생합니다. ",
      "- 청색 혹은 녹색 곰팡이로 성장합니다. ",
      "- 병변은 퍼지고 결국 과일이 썩고 쓰러집니다.",
    ],
  },
  "100276": {
    pathogen: "곰팡이",
    description: [
      "- 과일에 갈색의 부드럽고 움푹파인 병변이 나타난다. ",
      "- 회색에서 갈색반점이 있고 잎의 중심이 빛난다. ",
      "- 잎이 검게 되어 시들게 된다. ",
      "- 줄기에 검고 움풀패인 동고병이 생기게 된다.",
    ],
  },
  "100280": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 물에 적은 듯한 작고 흰색-연한 노란색의 반점이 나타난다. ",
      "- 시간이 지나면서 황갈색 중심으로 움푹 패인 길쭉한 갈색 반점이 형성됩니다. ",
      "- 괴사 부위가 크면 광범위하게 식물이 손상됩니다.",
    ],
  },
  "100283": {
    pathogen: "곰팡이",
    description: [
      "- 잎과 껍질에 젖은 듯한 변색된 원형의 띠와 고리가 나타납니다.",
      "- 감염된 조직에는 연한 갈색 솜과 같은 균사체가 나타납니다. ",
      "- 이삭으로 퍼집니다. ",
      "- 식물 전체가 병들 수 있습니다.",
    ],
  },
  "100289": {
    pathogen: "곰팡이",
    description: [
      "- 윗잎 표면에 작고 창백한 반점들이 생깁니다. ",
      "- 오래된 병변은 밝은 후광과 함께 갈색으로 변하게 됩니다. ",
      "- 잎 아랫면에는 흐릿한 회색 모양이 있습니다.",
    ],
  },
  "100293": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 밑면에 흰 가루와 윗면은 노란 반점이 나타납니다. ",
      "- 밀가루 같은 물질은 잎에서 닦아낼 수 있습니다. ",
      "- 감염된 부위는 오그라들고, 잎은 탈락됩니다,. ",
      "- 식물이 죽을 수도 있습니다.",
    ],
  },
  "100296": {
    pathogen: "곰팡이",
    description: [
      "- 잎사귀가 물기를 머금고 있습니다. ",
      "- 식물은 죽게됩니다. ",
      "- 곰팡이가 증식하게 됩니다.",
      "- 어린 과일, 줄기, 꽃봉오리에 영향을 미칩니다.",
    ],
  },
  "100297": {
    pathogen: "곰팡이",
    description: ["- 잎사귀에 엽록소 줄무늬가 생깁니다. ", "- 둔탁하고 부스스한 외관"],
  },
  "100318": {
    pathogen: "곰팡이",
    description: [
      "- 잎 양쪽에 작고 검게 돋은 반점이 나타납니다. ",
      '- 갈색 병변으로 둘러쌓인 "어안" 증상이 나타납니다. ',
      "- 잎사귀 전체가 반점으로 덮여 말라버립니다.",
    ],
  },
  "100321": {
    pathogen: "곰팡이",
    description: [
      "- 잎사귀에 원형의 노란 반점이 나타납니다. ",
      "- 잎은 말라서 탈락하게 됩니다. ",
      "- 과일은 썩기 시작해서 떨어지게 됩니다.",
    ],
  },
  "100322": {
    pathogen: "곰팡이",
    description: [
      "- 흙 안에서 씨앗이 썩게됩니다. ",
      "- 모종 줄기 밑 부분에 물기에 젖은 어두운 병변이 나타납니다. ",
      "- 가벼운 곰팡이가 모종이 성장하면서 덮게 됩니다.",
    ],
  },
  "100323": {
    pathogen: "곰팡이",
    description: [
      "- 잎, 줄기, 꼬투리 또는 과일에 물에 젖은 병변이 나타납니다. ",
      "- 선명한 색상의 테투리로 둘러싸인 타원형의 병변",
      "- 줄기 라앳쪽은 짙은 갈생이고 거칩니다. ",
      "- 심할 경우 식물이 죽게됩니다.",
    ],
  },
  "100328": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 오란색 후광이 있는 갈색반점이 생기고 열매가 작아집니다. ",
      "- 심한 감염은 조기 잎을 탈락시키고 줄기 역류를 일으킵니다.",
    ],
  },
  "100329": {
    pathogen: "곰팡이",
    description: [
      "- 새싹, 잎, 꽃이 비상적으로 발육합니다. ",
      "- 다발 외관을 만듭니다. ",
      "- 성장을 저해합니다. ",
      "- 식물성 기형과 꽃 기형이 있습니다. ",
      "- 꽃과 새싹의 무질거사 만연해 있습니다.",
    ],
  },
  "100335": {
    pathogen: "곰팡이",
    description: ["- 잎 밑면에 불규칙한 갈색 반점이 있다. ", "- 잎이 마르고 시듭니다. ", "- 고엽제 발생"],
  },
  "100337": {
    pathogen: "곰팡이",
    description: ["- 잎은 노란 테두리와 비상적인 미세한 반점이 보일 수 있다."],
  },
  "100348": {
    pathogen: "곰팡이",
    description: [
      "- 잎에 물기를 머금은 반점이 나타납니다. ",
      "- 청동-갈색 작은 반점이 발생합니다. ",
      "- 붉은 갈색 테두리의 노란 중앙의 반점",
    ],
  },
  "100356": {
    pathogen: "곰팡이",
    description: ["- 검은 알맹이", "- 오그라들고 변형됩니다. ", "- 식물이 발육이 멈추게 됩니다."],
  },
  "100357": {
    pathogen: "곰팡이",
    description: ["- 잎에 물에 젖은 반점이 나타납니다. ", "- 검은 여백과 노란 테두리로 둘러싸인 흰 반점"],
  },
  "100360": {
    pathogen: "곰팡이",
    description: ["검색안됨"],
  },
  "100365": {
    pathogen: "곰팡이",
    description: ["- 잎이 노래지고 뿌리가 썩습니다. ", "- 뿌리 줄기 조직이 갈색으로 변색됩니다."],
  },
  "100371": {
    pathogen: "곰팡이",
    description: ["검색안됨"],
  },
  "100372": {
    pathogen: "곰팡이",
    description: ["검색안됨"],
  },
  "100375": {
    pathogen: "곰팡이",
    description: [
      "- 잎의 양면에 여러 개의 작고 타원형의 불규칙한 점이 나타난다.",
      "- 반점이 합쳐져서 불규칙한 병변을 형성합니다.",
      "- 식물은 그을린 모습을 보이며 뿌리줄기의 수확량이 감소합니다.",
    ],
  },
  "100377": {
    pathogen: "곰팡이",
    description: ["검색안됨"],
  },
  "100378": {
    pathogen: "곰팡이",
    description: ["검색안됨"],
  },
  "200004": {
    pathogen: "바이러스",
    description: [
      "- 잎에 연두색 모자이크 무늬가 나타납니다. ",
      "- 잎이 올라오거나 주름이 잡히거나 일그러집니다. ",
      "- 잎이 말립니다. ",
      "- 성장이 둔합니다.",
    ],
  },
  "200006": {
    pathogen: "바이러스",
    description: [
      "- 잎과 과일의 노란 모자이크 무늬가 나타납니다. ",
      "- 잎과 잎자루가 아래로 구부러지고 구겨진다. ",
      "- 성장이 둔해지고 변형이 됩니다. ",
      "- 꽃에 하얀 줄무늬가 생깁니다.",
    ],
  },
  "200007": {
    pathogen: "바이러스",
    description: [
      "- 어린 잎에 녹색 또는 노란색 반점이 나타나고 정맥이 깨끗합니다. ",
      "- 잎이 노랗게 변하고 구겨지고, 변형됩니다. ",
      "- 성장이 둔해집니다. ",
      "- 과일에 반점과 줄무늬가 나타나거나 일그러집니다.",
    ],
  },
  "200008": {
    pathogen: "바이러스",
    description: [
      "- 잎 가장자리가 위쪽으로 올라갑니다. ",
      "- 어린 잎맥 사이로 노랗게 변합니다. ",
      "- 오래된 잎은 뻣뻣하고 부서지기 쉬우며 밑면은 보라색입니다. ",
      "- 성장이 둔해집니다. ",
      "- 줄기가 뻣뻣하고 위쪽을 향합니다.",
    ],
  },
  "200009": {
    pathogen: "바이러스",
    description: [
      "- 잎의 끝부분부터 시작하여 노란색-짙은 녹색의 모자이크 무늬가 나타납니다. ",
      "- 잎과 새싹에 검은 괴사 반점과 선이 생깁니다. ",
      "- 성장이 둔화됩니다.",
    ],
  },
  "200012": {
    pathogen: "바이러스",
    description: [
      "- 잎과 꽃잎에 괴사 반점과 불규칙한 형태의 병변이 나타납니다.",
      "- 꼭대기 꽃봉오리가 죽고 아래쪽으로 괴사가 퍼집니다.",
    ],
  },
  "200014": {
    pathogen: "바이러스",
    description: [
      "- 주름진 모양의 잎에 불규칙한 노란색-녹색 반점이 나타납니다. ",
      "- 병변이 확대되어 짙은 갈색이 됩니다.",
      "- 더 적은, 더 작은 꼬투리들, 때때로 위로 구부러집니다.",
      "- 성장 저해.",
      "- 종자 품질 및 수량 감소",
    ],
  },
  "200015": {
    pathogen: "바이러스",
    description: [
      "- 과일에 짙은 녹색 고리가 나타납니다. ",
      "- 잎에 노란 모자이크 무늬가 생깁니다. ",
      "- 줄기에 반점과 줄무늬가 생깁니다.",
    ],
  },
  "200016": {
    pathogen: "바이러스",
    description: [
      "- 잎들이 안쪽으로 말립니다. ",
      "- 정맥이 두꺼워지고 깨끗해 집니다. ",
      "- 잎이 가죽처럼 무르고 부서지기 쉬워집니다. ",
      "- 고엽제는 성장을 방해합니다. ",
      "- 일그러진 과일들이 생깁니다.",
    ],
  },
  "200017": {
    pathogen: "바이러스",
    description: ["- 잎사귀에 모자이크 무늬가 나타납니다. ", "- 잎이 약간 변형됩니다. ", "- 성장이 둔합니다."],
  },
  "200018": {
    pathogen: "바이러스",
    description: [
      "- 잎 중간부터 가장자리까지 노란색 줄무늬가 나타납니다. ",
      "- 나중에 노란색 얼룩이 갈색-검은색으로 변합니다. ",
      "- 잎 가장자리부터 중간까지 죽어갑니다. ",
      "- 성장이 둔합니다.",
    ],
  },
  "200019": {
    pathogen: "바이러스",
    description: [
      "- 잎맥이 두드러집니다. ",
      "- 줄기에 잡티, 줄무늬 및 병변의 변색, 꽃 피울 때 모자이크 무늬 뚜렷해진다.",
    ],
  },
  "200020": {
    pathogen: "바이러스",
    description: [
      "- 잎에 짙은 녹색 줄무늬가 있습니다. ",
      "- 잎맥과 평행한 작고 짙은 녹색점들이 나타납니다. ",
      "- 잎이 가늘고 구부러지고 가장자리가 시들해집니다. ",
      "- 작은 녹색 잎들이 식물의 관을 형성합니다.",
    ],
  },
  "200023": {
    pathogen: "바이러스",
    description: [
      "- 초기 감염 단계에서는 어린 잎의 기저부에 작고 엽록소성 원형 반점이 발견됩니다.",
      "- 그러면 반점의 수가 증가하고 그것들이 합쳐지는 경향이 있습니다. ",
      "- 잎맥과 평행하게 자라는 좁고 흰색에서 노란색의 줄무늬로 발달합니다.",
      "- 그것들은 잎 전체를 덮을 수 있고 식물의 발육, 소의 불완전한 발달, 곡물 충전의 손상으로 이어질 수 있습니다.",
    ],
  },
  "200024": {
    pathogen: "바이러스",
    description: ["- 잎에 연두색 모자이크 패턴을 보입니다. ", "- 식물은 꽃과 꼬투리 없이 무성하게 자랍니다."],
  },
  "200031": {
    pathogen: "바이러스",
    description: [
      "- 어린 잎날 위의 전신 모자이크 패턴이 나타납니다. ",
      "- 좁은 엽록소 줄무늬는 정맥과 평행하게 뻗어 있습니다.",
      "- 오래된 잎의 괴사합니다.",
      "- 오래된 잎의 붉은색 부분",
      "- 성장이 저하되고 척박한 줄기가 있습니다.",
    ],
  },
  "200034": {
    pathogen: "바이러스",
    description: [
      "- 종자성 감염증 블랙그램(cv.t - 9) 파종 후 3주 후에 주로 나타난다.",
      "- 초기 증상에서는 세 번째 삼엽엽의 크기가 커지고 밝은 녹색이 나타나며, 그 이후에는 전형적인 주름이 더 두드러진다.",
    ],
  },
  "200035": {
    pathogen: "바이러스",
    description: [
      "- 짙은 갈색 반점이 나타났다가, 나중에 잎에 반점이 확대됩니다. ",
      "- 성장이 둔합니다. ",
      "- 어린 과일에 연두색 고리가 생깁니다. ",
      "- 잘 익은 과일에 갈색 고리와 반점이 생깁니다. ",
      "- 과일이 가끔 변형됩니다.",
    ],
  },
  "200036": {
    pathogen: "바이러스",
    description: ["- 잎이 노랗고 곱슬곱슬해집니다. ", "- 성장이 둔해집니다. ", "- 과일의 수가 감소 됩니다."],
  },
  "200037": {
    pathogen: "바이러스",
    description: [
      "- 감염된 잎은 녹색과 노란색 반점등이 나타나고 일그러집니다. ",
      "- 식물은 다양한 정도까지 발육이 중단되고 과일 세트는 심각하게 감소될 수 있습니다.",
      "- 숙성된 과일은 표면에 갈색 반점이 생기고 과육에 내부 갈색 반점이 생긴다.",
    ],
  },
  "200039": {
    pathogen: "바이러스",
    description: [
      "- 잎에 연두색 반점과 모자이크 무늬가 나타납니다. ",
      "- 감염된 과일은 크기가 작아보이고 괴사를 보일 수 도 있습니다.",
    ],
  },
  "200042": {
    pathogen: "바이러스",
    description: [
      "- 잎에 있는 모자이크 무늬로, 옅은 노란색에서 흰색의 위향병이 초기에 발달합니다.",
      "- 중증도에 따라 잎의 변형, 전단 크기 감소합니다. ",
      "- 식물은 성장이 저해되고 덩이줄기는 극적으로 감소합니다.",
    ],
  },
  "200047": {
    pathogen: "바이러스",
    description: [
      "- 짙은 녹색 조직으로 모자이크 패턴을 형성하는 잎에 노란색 또는 갈색의 큰 반점이 생깁니다. ",
      "- 누렇게 두껍고 기형적인 잎맥이 나타납니다. ",
      "- 식물의 기절, 꽃의 감소, 볼 빠짐, 캐노피 감소합니다.",
    ],
  },
  "200052": {
    pathogen: "바이러스",
    description: [
      "- 잎 가장자리의 위쪽 말립니다. ",
      "- 정맥이 노랗게 변합니다.",
      "- 잎 크기 감소합니다. ",
      "- 오래된 잎들은 가죽이 많고 부서지기 쉽습니다. ",
      "- 식물의 발작.",
      "- 작은 크기의 과일 군집하게 됩니다.",
    ],
  },
  "200055": {
    pathogen: "바이러스",
    description: [
      "- 잎과 과일의 모자이크 무늬가 나타납니다. ",
      "- 과일에 갈색 반점과 엽록소 얼룩이 있습니다. ",
      "- 잎과 잎자루의 변형 및 구겨집니다. ",
      "- 성장을 방해하고 꽃에 흰 줄무늬가 있습니다.",
    ],
  },
  "200061": {
    pathogen: "바이러스",
    description: [
      "- 잎에 밝은 노란색 반점과 모자이크 얼룩이 생깁니다. ",
      "- 청동색으로 변색됩니다. ",
      "- 괴사성 고리와 과일의 반점이 나타납니다.",
    ],
  },
  "200065": {
    pathogen: "바이러스",
    description: [
      "- 이 오크라 바이러스성 질병은 상당한 수확량 손실을 일으킬 수 있다.",
      "- 모든 작물 단계에서 발생하며, 흰파리(Bemisia tabaci)에 의해 전염된다.",
      "- 잎에 노란 정맥과 모자이크 무늬 발생",
      "- 조기 감염시 과일 수확량이 최대 96%까지 감소될 수 있습니다.",
    ],
  },
  "200072": {
    pathogen: "바이러스",
    description: ["검색안됨"],
  },
  "200077": {
    pathogen: "바이러스",
    description: ["검색안됨"],
  },
  "200078": {
    pathogen: "바이러스",
    description: ["검색안됨"],
  },
  "300002": {
    pathogen: "박테리아",
    description: [
      "- 아래쪽 잎맥을 따라 녹색 반투명 변색이 나타난다.",
      "- 병변은 세로 방향으로 확장되며 합쳐지는 경향이 있습니다.",
      "- 중앙의 갈색 괴사 줄무늬가 말라 빠지면서 잎이 누더기처럼 보인다.",
      "- 세균성 진액은 감염된 조직에서 감지될 수 있습니다.",
    ],
  },
  "300003": {
    pathogen: "박테리아",
    description: [
      "- 어린 잎에 작은 황록색 병변이 나타납니다. ",
      "- 노란색 원을 가진 오래된 잎사귀에 어둡고 물에 젖은 병변이 생깁니다. ",
      "- 잎이 변형되고 뒤틀립니다. ",
      "- 과일이 거칠어지고 갈색이 되어 딱지가 생깁니다.",
    ],
  },
  "300006": {
    pathogen: "박테리아",
    description: [
      "- 잎 가장자리에 노란색, 쐐기 모양의 반점이 나타납니다.",
      "- 반점가 확대되어 갈색으로 변합니다.",
      "- 잎맥이 검게 변합니다.",
    ],
  },
  "300009": {
    pathogen: "박테리아",
    description: [
      "- 식물의 윗부분은 아무런 증상이 없습니다. ",
      "- 감자 덩어리 표면에 갈색, 코르크 같은 물집이 생깁니다. ",
      "- 감자 덩어리의 얕고 깊은 구멍과 그물 같은 균열이 발생합니다.",
    ],
  },
  "300011": {
    pathogen: "박테리아",
    description: [
      "- 잎 아래쪽에 물이 스며들어 반투명하고 각진 반점이 나타납니다. ",
      "- 나중에 합체하여 적갈색 반점을 형성합니다.",
      "- 이 병변에서 세균성 점액이 배어 나온다.",
      "- 진한 과일 줄기와 시든 과일.",
    ],
  },
  "300013": {
    pathogen: "박테리아",
    description: [
      "- 잎사귀에 검고, 물에 젖은 반점이 나타납니다. ",
      "- 반점은 나중에 마르고 옅은 갈색이나 회백색으로 변한다.",
      "- 조기 탈락하게 됩니다.",
    ],
  },
  "300014": {
    pathogen: "박테리아",
    description: [
      "- 잎에 회록색 줄무늬가 생긴다. ",
      "- 잎이 노랗고 시듭니다. ",
      "- 낙엽에서 흰 물방울이 뚝뚝 떨어집니다.",
    ],
  },
  "300020": {
    pathogen: "박테리아",
    description: [
      "- 나뭇잎에 노란색 테두리로 되있는 녹-갈색의 사마귀가 나타납니다. ",
      "- 추후 병변은 중심부가 기름지고 연갈색-회색으로 변색합니다. ",
      "- 과일과 잔가지에도 비슷한 증상이 나타납니다.",
    ],
  },
  "300023": {
    pathogen: "박테리아",
    description: [
      "- 잎 마디에서 가지, 줄기 및 가지에 매듭이 있는 경우(보통 항상은 아님).",
      "- 이러한 나무껍질의 변형은 수 센티미터에 이를 수 있다.",
      "- 활력이 떨어지고 성장이 줄어든 나무.",
    ],
  },
  "300027": {
    pathogen: "박테리아",
    description: [
      "- 잎 중간 정맥을 따라 녹색 줄무늬가 나타난다. ",
      "- 붉은 줄무늬가 잎사귀 전체에 퍼져있습니다. ",
      "- 잎이 시들고 썩습니다. ",
      "- 성장이 저해됩니다.",
    ],
  },
  "300028": {
    pathogen: "박테리아",
    description: [
      "- 식물의 시듭니다. ",
      "- 잎은 녹색으로 유지되고 붙어 있습니다.",
      "- 뿌리와 아래 줄기는 갈색이 된다.",
      "- 뿌리가 썩고 누런 분비물이 배설된다.",
    ],
  },
  "300030": {
    pathogen: "박테리아",
    description: [
      "- 잎에 노란색에서 갈색의 작은 반점이 있다.",
      "- 나중에 어두운 갈색, 불규칙하거나 각진 색으로 변한다.",
      "- 황록색 - 녹색 테두리.",
      "- 병변은 꼬투리에도 나타납니다.",
    ],
  },
  "300032": {
    pathogen: "박테리아",
    description: [
      "- 옅은~중갈색 알갱이 변색합니다.",
      "- 나중에, 곡물은 다른 박테리아나 곰팡이로부터 회색, 검은색 또는 분홍색으로 변할 수 있다.",
      "- 원추체는 직립 상태를 유지한다.",
    ],
  },
  "300034": {
    pathogen: "박테리아",
    description: [
      "- 잎의 가장자리가 불규칙하고 정맥과 평행한 길쭉한 황갈색 병이 나타납니다. ",
      "- 점차적으로 엽색 현상으로 이어집니다.",
      "- 건조한 세균의 빛나는 산출물은 병변과 검은 주근깨에 배어 있다.",
      "- 묘목의 시들어 죽는다",
    ],
  },
  "300035": {
    pathogen: "박테리아",
    description: [
      "- 잎과 잎집, 줄기의 뒷부분이 변색됩니다. ",
      "- 악취와 식물의 윗부분은 다른 것들로부터 쉽게 제거된다.",
      "- 줄기의 내부 변색 및 끈적끈적한 썩음",
    ],
  },
  "300050": {
    pathogen: "박테리아",
    description: [
      "- 잎과 과일에 노란색 테두리가 있는 작고 어두운 반점이 나타난다.",
      "- 줄기와 꽃줄기도 영향을 미친다.",
    ],
  },
  "300055": {
    pathogen: "박테리아",
    description: ["검색안됨"],
  },
  "300058": {
    pathogen: "박테리아",
    description: ["검색안됨"],
  },
  "300060": {
    pathogen: "박테리아",
    description: ["검색안됨"],
  },
  "500004": {
    pathogen: "진드기",
    description: ["검색안됨"],
  },
  "500009": {
    pathogen: "진드기",
    description: [
      "- 봄철에 잎이 낫 모양으로 휘고 죽은 식물성 싹이 납니다. ",
      "- 꽃봉오리가 변색되고 꽃망울이 탈락, 싹의 성장이 둔화됩니다.",
    ],
  },
  "500011": {
    pathogen: "진드기",
    description: [
      "- 잎, 잎새, 꽃봉오리, 열매의 변형 및 변색됩니다. ",
      "- 잎 밑에 짙은 갈색 부분.",
      "- 성장 저해.",
      "- 싹이 뒤로 꺾이는 것이 발생할 수 있습니다.",
    ],
  },
  "500014": {
    pathogen: "진드기",
    description: [
      "- 물집 - 윗잎 표면의 붓기와 같은 것.",
      "- 융기된 부위 아래에 짧은 잔털 층(흰색에서 붉은색까지)",
      "- 작은 곤충들, 돋보기 없이는 보기 힘들다.",
    ],
  },
  "500018": {
    pathogen: "진드기",
    description: [
      "- 이 해충은 오렌지의 러스트 진드기와 레몬의 실버 진드기로 알려져 있습니다.",
      "- 1.3cm 이상의 과일의 노출된 표면을 먹고 산다.",
      "- 과일 껍질은 은빛, 불그스름하거나 검게 변한다.",
      "- 늦봄부터 늦여름까지 피해가 발생합니다.",
      "- 이로 인해 생과일 시장에서는 등급 및 주스 품질이 저하됩니다.",
      "- 심할 경우 수율이 감소합니다.",
    ],
  },
  "500023": {
    pathogen: "진드기",
    description: ["- 잎의 복부 표면에 흰 거미줄이 있습니다. ", "- 진드기에 의한 먹이로 인한 흰 반점이 나타납니다."],
  },
  "500024": {
    pathogen: "진드기",
    description: ["- 윗잎 표면에 흰색 코팅됩니다. ", "- 잎 변색됩니다. ", "- 고엽제 발생."],
  },
  "600002": {
    pathogen: "곤충",
    description: ["- 곱슬곱슬하고 변형된 잎.", "- 잎과 새싹 아래에 있는 작은 곤충들. 성장이 멈춘다."],
  },
  "600003": {
    pathogen: "곤충",
    description: ["- 윗잎에 작은 은색 반점이 있다. ", "- 잎이 노랗게 물든다. ", "- 잎, 꽃, 과일의 변형"],
  },
  "600005": {
    pathogen: "곤충",
    description: [
      "- 잎과 새싹이 시들고 누렇게 변합니다. ",
      "- 나무껍질, 새싹, 뿌리가 붓고 통증을 받게 됩니다. ",
      "- 꽃봉우리 근처에 흰 솜털이 발생합니다. ",
      "- 곰팡이균에 걸리기 쉽습니다. ",
      "- 성장이 저해됩니다.",
    ],
  },
  "600011": {
    pathogen: "곤충",
    description: [
      "- 울퉁불퉁한 나무줄기 또는 가지에 구멍과 유충의 배설물이 있습니다. ",
      "- 나무껍질을 도려내어 제거하면, 삽나무에서 바로 터널의 배열을 발견할 수 있다.",
      "- 이 특징적인 터널링은 마야 키푸를 닮았다.",
    ],
  },
  "600013": {
    pathogen: "곤충",
    description: [
      "- 애벌레는 기장의 머리에 있는 발달된 곡식을 먹고 삽니다.",
      "- 원뿔 표면에 나선형 트랙이 솟아 있습니다.",
      "- 보통 수확량 감소.",
      "- 연한 황색 유충, 나중에 불그스름하거나 분홍빛을 띤다.",
    ],
  },
  "600015": {
    pathogen: "곤충",
    description: [
      "- 나뭇잎에 노란 반점이 있다.",
      "- 진한 검정색 가루 모양의 곰팡이가 핍니다.",
      "- 잎이 말리거나 컵 모양으로 변형됩니다. ",
      "- 성장 저해.",
      "- 작고 하얀색에서 노란빛까지 나는 곤충.",
    ],
  },
  "600018": {
    pathogen: "곤충",
    description: [
      "- 잎맥의 사이에 영양분공급 문제가 생긴다. ",
      "- 잎의 골격화",
      "- 과일에 얇은 구멍이 나타난다. ",
      "- 성장이 둔해진다. ",
      "- 심한 고엽제",
      "- 성충은 타원형이고 주황색이며, 28개의 검은 반점이 있으며, 등에 짧고 부드러운 털이 있다.",
    ],
  },
  "600019": {
    pathogen: "곤충",
    description: [
      "-흰솜-잎, 줄기 꽃, 과일 위에 모여있는 곤충같다. ",
      "- 잎이 노랗게 물들고 곱슬거립니다. ",
      "- 성장이 둔해집니다.",
    ],
  },
  "600020": {
    pathogen: "곤충",
    description: [
      "- 나뭇잎을 노랗게 물들인다. ",
      "- 구멍같은 작은 것들을 만든다. ",
      "- 작고 어둡고 반짝거리는 딱정벌레이다.",
    ],
  },
  "600022": {
    pathogen: "곤충",
    description: ["- 터널 앞에 회색같은 선이 잎에 있다. ", "- 잎맥으로 터널이 구분된다. ", "- 잎이 조기 탈락한다."],
  },
  "600028": {
    pathogen: "곤충",
    description: [
      "- 잎사귀에 큰 구멍이 뚫려 있습니다. ",
      "- 잎 안쪽이나 양배추의 중앙에 초록색 배설물이 있습니다. ",
      "- 애벌레와 배설물이 종종 식물에 발견됩니다.",
    ],
  },
  "600032": {
    pathogen: "곤충",
    description: [
      "- 줄기의 잎껍질에 작은 구멍이 나있습니다. ",
      "- 젤리와 같은 배설물이 있습니다. ",
      "잎이 노랗게 물듭니다. ",
      "- 성장이 저해됩니다. ",
      "- 성체는 검은색이고 뾰족한 머리가 있고 반짝거립니다.",
    ],
  },
  "600033": {
    pathogen: "곤충",
    description: [
      "- 땅에 무리를 지어 알을 낳습니다. ",
      "- 알은 2~3주 후에 부화하여 땅속으로 굴을 파고 들어가 나무와 관목의 뿌리를 먹고 산다.",
      "- 식물은 성장이 저해되고 악천후에서 바람에 날라갈 수 있다.",
    ],
  },
  "600036": {
    pathogen: "곤충",
    description: [
      "- 애벌레는 누더기 모양의 부드러운 새 잎을 먹고 산다.",
      "- 어린 잎은 세로로 말린다.",
      "- 말린 잎은 갈색이고 결국 건조해진다.",
      "- 심한 감염에서는 식물의 전체 부분이 갈색으로 보이고 잎이 떨어집니다.",
    ],
  },
  "600037": {
    pathogen: "곤충",
    description: [
      "- 윗잎 표면에 가늘고 길고 하얀 줄무늬가 있다.",
      "- 해당 분야는 풍화 및 노후화 될 수 있습니다.",
      "- 성체는 붉은 머리와 다리를 가진 검푸른 날개 덮개를 가지고 있다.",
      "- 알은 잎 아래쪽에 낳는다.",
    ],
  },
  "600038": {
    pathogen: "곤충",
    description: [
      "- 어린 잎에 불규칙한 회색에서 흰색 배설물이 있습니다. ",
      "- 나중에 괴사하게 됩니다. ",
      "- 갤러리와 줄기로 들어가는 큰 굴.",
      "- 과일이 출입하는 곳에 검은 자국이 있음.",
      "- 성장 저해됩니다. ",
      "- 은갈색 성체, 길이 5-7mm.",
    ],
  },
  "600039": {
    pathogen: "곤충",
    description: [
      "- 모든 식물 조직에 손상을 일으킨다. ",
      "- 배설물 나뭇잎에서 찾을 수 있다.",
      "- 애벌레는 이마에 Y자 무늬가 있고 뒷면에 4개의 점이 있습니다.",
    ],
  },
  "600041": {
    pathogen: "곤충",
    description: [
      "- 잎사귀에 '창문'과 같은 구멍을 일으킵니다. ",
      "- 불규칙한 구멍과 너덜너덜한 잎 가장자리가 나타납니다. ",
      "- 다 자란 나방은 짙은 갈색이고, 앞날개는 청동색에서 황금색까지 반짝이는 반점이 있는 갈색입니다.",
    ],
  },
  "600046": {
    pathogen: "곤충",
    description: [
      "- 잎이 광범위하게 손상됩니다. ",
      "- 고엽제",
      "- 회갈색 몸과 무늬가 있는 앞날개를 가진 나방입니다. ",
      "- 윗 잎에 알무더기가 보입니다.",
    ],
  },
  "600061": {
    pathogen: "곤충",
    description: ["N/A"],
  },
  "600063": {
    pathogen: "곤충",
    description: ["- 어린 잎이 물갈퀴처럼 엮여있습니다. ", "- 꼴과 꼬투리도 영향을 받습니다."],
  },
  "600070": {
    pathogen: "곤충",
    description: [
      "- 익은 과일에 삼각형 모양의 구멍이 선명하게 나타납니다. ",
      "- 처음에는 짙은 녹색이지만 나중에는 황갈색으로 변한다.",
      "- 유충이 과육을 손상시킨다.",
      "- 올리브 과일은 마르고 일찍 탈락할 수 있습니다.",
    ],
  },
  "600071": {
    pathogen: "곤충",
    description: [
      "-그 해충은 꽃봉오리, 꽃, 부드러운 새싹, 작은 과일을 먹이로 하여 조직을 손상시킨다.",
      "- 배설물은 부드러운 곰팡이를 끌어당겨 광합성을 제한한다.",
      "- 왁스분비는 꽃과 작은 열매를 떨어뜨린다.",
    ],
  },
  "600072": {
    pathogen: "곤충",
    description: [
      "- 분비되는 꿀물은 개미와 부드러운 곰팡이를 끌어당긴다.",
      "- 썩은 잎은 일찍 떨어진다. ",
      "- 성장 저해.",
      "- 잎과 줄기 아랫면에 검은 비늘 덩어리",
    ],
  },
  "600073": {
    pathogen: "곤충",
    description: [
      "- 잎 밑면에 배설물이 있습니다. ",
      "- 비단실로 휘감쌉니다. ",
      "- 유충이 과일 속으로 들어가 먹으면서 과일을 떨어뜨립니다.",
    ],
  },
  "600075": {
    pathogen: "곤충",
    description: [
      "- 나무껍질 위쪽에서 진입합니다. ",
      "- 나무껍질 밑에서 횡단 터널을 뚫습니다. ",
      "- 나뭇가지의 구조적인 힘을 약하게 하여 시들게 합니다.",
    ],
  },
  "600086": {
    pathogen: "곤충",
    description: [
      "- 줄기에 손상을 주는 핀홀이 생깁니다. ",
      "- 내부 줄기 조직이 다 먹어 치웁니다. ",
      "- 약하고 발육이 잘 안 되는 식물.",
      "- 성숙한 식물은 서식지에 머물거나 떨어져 나가는 경향이 있다.",
    ],
  },
  "600088": {
    pathogen: "곤충",
    description: [
      "- 뿌리와 줄기에 먹이는 손상.",
      "- 줄기를 약하게 하여 서식지를 만듭니다. ",
      "- 등에 검은 줄무늬나 반점이 있는 황록색 딱정벌레.",
    ],
  },
  "600089": {
    pathogen: "곤충",
    description: [
      "- 잎 윗부분을 건조시킵니다. ",
      "- 잎 가장자리의 노란색 부분에서 시작됩니다. ",
      "- 나뭇잎을 때때로 말립니다. ",
      "- 성장 저해.",
      "- 녹색, 3~4mm 큰 잎사귀",
    ],
  },
  "600093": {
    pathogen: "곤충",
    description: [
      "- 죽은 잎에서 활동을 한다. ",
      "- 줄기 부근에 작은 구멍이 생깁니다. ",
      "- 손상된 줄기 내부에 배설물이 있습니다. ",
      "- 잎 날 끝 근처에 타원형 반점의 알을 낳습니다.",
    ],
  },
  "600097": {
    pathogen: "곤충",
    description: ["- 곡물이 채워지지않고 비어있습니다. ", "- 이삭 끝부분이 손상된다. ", "- 곡물이 변형되고 변색한다."],
  },
  "600098": {
    pathogen: "곤충",
    description: [
      "- 잎의 정맥을 따라 흰색, 평행한 줄무늬 또는 반점이 나타난다. ",
      "- 불규칙한 흰색 반점이 나타난다. ",
      "- 잎이 시들게 된다. ",
      "- 짙은 파란색-검은색을 띄며, 사각형 모양의 척추 딱정벌레",
    ],
  },
  "600104": {
    pathogen: "곤충",
    description: [
      "- 식물들의 칼집에 있는 작은 식물에 벌레들",
      "- 처음에는 주황색 - 노란색, 나중에는 갈색, 마른 잎.",
      "- 식물이 시들고 누렇게 변한다.",
    ],
  },
  "600105": {
    pathogen: "곤충",
    description: [
      "- 애벌레 주위에 볏짚을 접는다.",
      "- 칼날에 세로 방향으로 희끄무레하고 투명한 줄무늬가 있습니다.",
      "- 잎 끝에 있는 디스크 모양의 알이 있습니다. ",
      "- 날개에 갈색의 지그재그 선이 있는 나방.",
    ],
  },
  "600108": {
    pathogen: "곤충",
    description: [
      "- 잎끝이 변색된다. ",
      "- 잎의 수가 감소한다. ",
      "- 성장이 저하된다. ",
      "- 검은 반점이 있거나 연두색 잎 멍울이 나타난다.",
    ],
  },
  "600111": {
    pathogen: "곤충",
    description: [
      "- 잎 밑면의 갈색 배설물",
      "- 잎에 변색된 반점",
      "- 잎이 노랗게 물들고 곱슬거린다.",
      "- 작고 밝은 갈색과 흰색의 벌레",
    ],
  },
  "600117": {
    pathogen: "곤충",
    description: [
      "- 애벌레는 어린 잎을 채굴하고 덩굴 속으로 터널을 뚫는다.",
      "- 애벌레가 터널을 벗어나면 곰팡이, 박테리아, 진드기가 자리를 잡는다.",
      "- 덩이줄기가 썩어서 불쾌한 냄새가 난다.",
    ],
  },
  "600122": {
    pathogen: "곤충",
    description: [
      "- 잎 변형 - 뒤틀리거나 구부러진 외관",
      "- 잎 표면의 흰색 또는 회색 터널.",
      "- 성장 저해. ",
      "- 과일 크기 감소.",
      "- 작고 갈색이거나 회색의 나방이며, 앞날개에 어두운 점이 있다.",
    ],
  },
  "600126": {
    pathogen: "곤충",
    description: [
      "발음듣기복사하기번역하기자동완성자동완성",
      "- 잎 윗쪽에 작고 하얀 염소 반점이 있습니다.",
      "- 잎 아래쪽에 진한 추출물.",
      "- 잎이 노랗게 변하고 시든다.",
      "- 성체는 노란색에서 희끄무레한 색을 띠며, 반투명하고 줄무늬가 있는 레이스 같은 날개가 있다.",
    ],
  },
  "600136": {
    pathogen: "곤충",
    description: [
      "- 나뭇잎이 위로 둥글게 말린다.",
      "- 식물의 조기 탈락한다. ",
      "- 꽃과 과일도 영향을 미쳤다.",
      "- 몸집이 검고 갈색이고 날개가 누르스름한 작고 가느다란 곤충.",
    ],
  },
  "600137": {
    pathogen: "곤충",
    description: [
      "- 애벌레는 잎 끝, 가장자리, 그리고 때로는 잎 전체를 먹고 삽니다.",
      "- 밑면에서 원추꽃차례 절단.",
      "- 등줄기가 있는 풀잎 - 녹색의 어린 유충이 식물에 나타납니다.",
    ],
  },
  "600141": {
    pathogen: "곤충",
    description: [
      "- 나뭇잎에 먹어 손상시킨다. ",
      "- 탈피를 한다. ",
      "- 비늘이 있는 연한 갈색 나방.",
      " - 매끄러운 회색빛 - 검은 머리를 가진 갈색 애벌레.",
    ],
  },
  "600142": {
    pathogen: "곤충",
    description: ["검색안됨"],
  },
  "600152": {
    pathogen: "곤충",
    description: ["- 과일 껍질에 딱지, 회백색 또는 은색 흉터 ", "- 손상된 조직은 과일이 성숙할수록 더 커집니다."],
  },
  "600157": {
    pathogen: "곤충",
    description: [
      "- 뒤틀린 나뭇가지와 잎.",
      "- 잎이 시들고 누렇게 변한다.",
      "- 꿀물은 부드러운 곰팡이에 의한 식물의 시듦 촉진한다.",
      "- 과일 품질 저하.",
      "- 날개가 있든 없든 갈색에서 검은색 몸통.",
    ],
  },
  "600158": {
    pathogen: "곤충",
    description: [
      "- 나뭇잎과 과일에 노란색 줄무늬와 반점이 있습니다.",
      "- 심할 경우 잎이 조기 탈락합니다. ",
      "- 과일의 변형 및 감소",
      "- 잎, 과일, 새싹을 덮는 작고 검은 비늘.",
    ],
  },
  "600161": {
    pathogen: "곤충",
    description: [
      "- 꽃봉오리의 먹이 손상.",
      "- 실크 실은 꽃잎을 한데 묶는다.",
      "-나뭇잎 면에 먹이 구멍이 생긴다. .",
      "- 회색빛 - 갈색의 타원형 날개를 가진 갈색 나방.",
      "- 유충은 넓은 가로 분홍색 띠와 어두운 머리를 가진 하얀 몸을 가지고 있다.",
    ],
  },
  "600165": {
    pathogen: "곤충",
    description: ["- 새싹과 잔가지 손상으로 시들어 다시 죽는다.", "- 가지와 줄기에 번식의 흔적이 존재."],
  },
  "600169": {
    pathogen: "곤충",
    description: [
      "- 망고 잎 표면 및 부드러운 새싹의 구멍이 생긴다. ",
      "새로운 잎에 탄 자국이 나타난다. .",
      "물집이 잡힌 회색빛 - 나중에 큰 구멍이 되는 잎에 흰 반점이 있다.",
    ],
  },
  "600172": {
    pathogen: "곤충",
    description: [
      "- 과일 껍질 및 어린 잎의 반점 또는 심한 흉터",
      "- 흉터는 타원형이다.",
      "- 유충에게 먹이를 주어 뿌리에 손상을 입힌다.",
    ],
  },
  "600173": {
    pathogen: "곤충",
    description: [
      "- 잎이 노랗게 물들고 위로 컬링됨.",
      "- 후기의 여백에서 시작하여 갈색으로 변색됨.",
      "- 마른 잎의 벗겨지고 성장이 멈춘다.",
    ],
  },
  "600174": {
    pathogen: "곤충",
    description: [
      "- 윗잎에 붉은색, 보라색 또는 황록색 물집",
      "- 변형된 조직 주변의 변색된 부위",
      "- 심한 감염으로 인한 잎의 변형. ",
      "- 꿀물은 부드러운 곰팡이의 성장을 도와줍니다.",
    ],
  },
  "600178": {
    pathogen: "곤충",
    description: [
      "- 바구미는 울퉁불퉁한 꽃을 피우고 꽃과 잎을 구멍 꿇어 먹습니다. ",
      "- 성충은 꽃봉오리 안에 들어 있는 꽃 부분에 구멍을 냅니다.",
    ],
  },
  "600179": {
    pathogen: "곤충",
    description: [
      "- 꽃과 꽃봉오리에 먹이 자국을 남깁니다. ",
      "- 어린 싹의 끝과 줄기가 시듭니다. ",
      "- 성숙한 애벌레가 열매를 뚫고 들어가 마른 배설물로 입구 구멍을 막습니다. ",
      "- 과일 속은 풀로 가득 차 있습니다. ",
      "- 애벌레는 갈색 머리에 분홍색입니다.",
    ],
  },
  "600180": {
    pathogen: "곤충",
    description: [
      "- 딱정벌레는 꽃잎을 먹고 꽃을 손상시킵니다. ",
      "- 꽃의 암술과 씨방이 손상됩니다. ",
      "- 꼬투리 형성이 안됩니다.",
    ],
  },
  "600199": {
    pathogen: "곤충",
    description: [
      "- 섬유를 뚫고 씨앗을 먹고 삽니다. ",
      "- 섬유를 착색하게 합니다. ",
      "- 성충은 붉은 주황색이며, 앞날개에 검은 점이 두개 있습니다. ",
      "- 이 곤충은 검은색 또는 흰색 점으로 붉게 물들어 있습니다.",
    ],
  },
  "600227": {
    pathogen: "곤충",
    description: [
      "- 직물을 얼룩지게 꼬투리를 벗깁니다. ",
      "- 꼬투리를 변색시킵니다. ",
      "- 씨앗을 가볍게 만든다. ",
      "- 이 곤충은 분홍에서 갈색으로 색을 바꾼다.",
    ],
  },
  "600231": {
    pathogen: "곤충",
    description: [
      "- 녹색에서 갈색의 곤충은 잎의 아랫부분을 먹는다.",
      "- 잎이 노랗게 변하고 말라간다. 식물의 발육이 멈춘다.",
      "- 꿀물의 생산 및 잎 표면의 매끄러운 곰팡이의 성장",
      "- 옥수수 외에도 다른 풀과 곡류도 쉽게 공격한다.",
    ],
  },
  "600234": {
    pathogen: "곤충",
    description: [
      "- 이 곤충은 어린 새싹의 수액을 빨아들여 꽃봉오리, 꽃, 부드러운 새싹, 작은 열매를 손상시키고 약화시킵니다.",
      "- 잎은 꿀물과 부드러운 곰팡이로 뒤덮이고, 어린 꽃은 떨어지고 열매는 떨어진다. 잔가지들은 침입이 심할 때 다시 죽는다.",
    ],
  },
  "600235": {
    pathogen: "곤충",
    description: [
      "- 애벌레는 연두색의 부드러운 잎을 먹고 삽니다.",
      "- 잎 가장자리에서부터 안쪽 중간까지 먹이를 먹는다.",
      "- 이 곤충들은 일년 내내 활동적입니다.",
    ],
  },
  "600237": {
    pathogen: "곤충",
    description: ["- 잎과 줄기에 꿀 같은 퇴적물이 검은 곰팡이 균을 발생시킵니다. ", "- 그을음 모양입니다."],
  },
  "600246": {
    pathogen: "곤충",
    description: [
      "- 잎 윗부분에 있는 어린 잎을 먹습니다. ",
      "- 잎사귀에 작은 갈색 반점이 생깁니다. ",
      "- 멀리서도 심하게 영향을 받은 것 같이 보입니다. ",
      "- 어린잎이 겹쳐 보입니다.",
    ],
  },
  "600251": {
    pathogen: "곤충",
    description: [
      "- 붉은 비늘은 식물 조직을 빨아서 나뭇가지, 잎, 가지, 과일을 포함한 나무의 모든 공중 부분을 공격합니다.",
      "- 심한 감염은 잎이 노랗게 변하고 떨어지며, 나뭇가지와 팔다리가 다시 죽으면, 나무가 죽는 경우도 있다.",
    ],
  },
  "600258": {
    pathogen: "곤충",
    description: [
      "- 매듭 - 줄기가 땅 바로 위에 부풀어 오르는 것과 같다.",
      "- 식물의 시들기 및 건조.",
      "- 성장 저해.",
      "- 줄기는 쉽게 숙성할 수 있습니다.",
      "어린 식물들은 죽임을 당한다.",
      "- 작고, 어둡고, 날개 덮개와 머리에 흰색 톤이 있다.",
    ],
  },
  "600273": {
    pathogen: "곤충",
    description: [
      "- 식물의 가장자리를 먹고 안쪽으로 이동한다. ",
      "- 식물 성장을 방해한다. ",
      "-  옅은 회색, 날개 덮개와 성체 바구미의 머리에 어두운 무늬가 있다.",
    ],
  },
  "600289": {
    pathogen: "곤충",
    description: [
      "- 꽃, 과일, 꼬투리, 잎을 먹어 피해를 발생하게 한다. ",
      "- 구멍을 만들고 배설물을 남깁니다. ",
      "- 2차 병원균의 성장의 영향을 끼쳐 조직과 과일의 부패로 이어지게 한다.",
    ],
  },
  "600290": {
    pathogen: "곤충",
    description: [
      "- 잎에 커다란 먹이 구멍.",
      "- 뿌리와 지하 줄기의 깊은 구멍",
      "- 뿌리와 줄기의 썩고 시든다. ",
      "- 붉은 타원형 딱정벌레의 발생.",
    ],
  },
  "600296": {
    pathogen: "곤충",
    description: [
      "- 과일은 초기 단계에서 건강해 보입니다.",
      "- 후기에는 과일이 썩고 떨어집니다.",
      "- 푸르스름한 갈색 나비.",
      "- 다 자란 유충은 어두운 갈색이고 짧은 털과 흰 반점이 있다.",
    ],
  },
  "600297": {
    pathogen: "곤충",
    description: [
      "- 잎에 긁힌 표면의 외관",
      "- 부드러운 새싹과 잎을 함께 묶는 띠.",
      "- 잎은 건조하고 갈색으로 보인다.",
      "- 검은색과 흰색 줄무늬가 있는 녹색을 띤 유충.",
    ],
  },
  "600298": {
    pathogen: "곤충",
    description: [
      "- 흰 물질로 물갈퀴가 있는 잎.",
      "- 주황색 개미들.",
      "- 둥지는 숲의 나무에서 발견되지만, 지붕과 전신주를 포함한 높은 곳의 틈새에서도 볼 수 있다.",
    ],
  },
  "600301": {
    pathogen: "곤충",
    description: [
      "- 작고 사마귀 같은 반점이 잎, 싹, 새싹, 어린 열매를 덮고 있다.",
      "- 잎 밑과 과일 줄기에 출구 구멍이 보인다.",
      "- 잎이 조기 탈락되게 변형된다.",
    ],
  },
  "600344": {
    pathogen: "곤충",
    description: [
      "- 죽은 심장.",
      "- 잎을 가로지르는 일련의 평행한 구멍.",
      "- 줄기, 생장점, 잎의 내부사료",
      "- 은백색의 나방.",
    ],
  },
  "600367": {
    pathogen: "곤충",
    description: ["검색 안됨"],
  },
  "600368": {
    pathogen: "곤충",
    description: [
      "- 나무의 줄기와 주요가지에서 감염이 발생합니다.  ",
      "- 잎 밑면에 노란색 반점이 나타납니다. ",
      "- 낙엽이 조기에 탈락하게 됩니다. ",
      "- 잔가지가 꼭 깁니다. ",
      "- 결국 나뭇가지들이 죽게됩니다.",
    ],
  },
  "600373": {
    pathogen: "곤충",
    description: ["검색 안됨"],
  },
  "700001": {
    pathogen: "결핍",
    description: ["칼슘 결핍"],
  },
  "700002": {
    pathogen: "결핍",
    description: ["칼륨 결핍"],
  },
  "700004": {
    pathogen: "결핍",
    description: ["마그네슘 결핍"],
  },
  "700005": {
    pathogen: "결핍",
    description: ["인 결핍"],
  },
  "700006": {
    pathogen: "결핍",
    description: ["질소 결핍"],
  },
  "700007": {
    pathogen: "결핍",
    description: ["철분 결핍"],
  },
  "700009": {
    pathogen: "결핍",
    description: ["망간 부족"],
  },
  "700048": {
    pathogen: "결핍",
    description: ["아연 결핍"],
  },
  "700049": {
    pathogen: "결핍",
    description: ["붕소 결핍"],
  },
  "800004": {
    pathogen: "기타",
    description: [
      "- 잎에 털이 많고, 녹색에서 주황색 반점이 나타납니다. ",
      "- 어린 줄기의 껍질 균열됩니다. ",
      "- 고엽제.",
      "- 과일의 변형.",
      "- 낮게 달려있는  가지에 대한 감염 증가합니다.",
    ],
  },
  "800033": {
    pathogen: "기타",
    description: [
      "- 잎에 갈색의 테두리이 있는 황록색의 기름진 반점이 나타납니다.  ",
      "- 불규칙한 갈색 반점으로 변한다.",
      "- 반점 아래 흰색에서 회색의 솜털 같은 코트.",
      "- 새싹, 힘줄, 꽃가루에 영향을 줄 수 있습니다.",
      "- 성장이 둔해집니다.",
    ],
  },
  "800042": {
    pathogen: "기타",
    description: [
      "- 잎 표면에 갈색으로 솟아오른 가장자리와 황갈색의 각진 반점 또는 반점이 나타난다. ",
      "- 반점의 중심은 건조해지고 빠질 수 있습니다.",
      "- 심한 경우, 황색 염화 헤일로가 반점 주위에 발달합니다.",
    ],
  },
  "800060": {
    pathogen: "기타",
    description: ["- 잎축을 따라 잎조직을 제거합니다.", "- 두 개의 뿔을 가진 녹색 애벌레."],
  },
  "800061": {
    pathogen: "기타",
    description: [
      "- 반짝이는 녹색, 길이 5mm~11cm",
      "- 잎, 새싹, 원추꽃차례에 먹이 자국(도려내기)",
      "- 성인들은 떼를 지어 이동한다.",
    ],
  },
  "800078": {
    pathogen: "기타",
    description: ["- 잎이 테두리에서 부터 시들고 황변됩니다. ", "- 과일이나 나무껍질도 탈피 후 영향을 받습니다."],
  },
  "800080": {
    pathogen: "기타",
    description: ["- 과일 껍질에 갈색  반점이 나타납니다. ", "- 과일이 물러집니다."],
  },
  "800089": {
    pathogen: "기타",
    description: [
      "- 동심원 고리로 둘러싸인 원형의 움푹 들어간 병변입니다. ",
      "- 갈색 과일은 오렌지색 젤라틴 모양의 기질을 가지고 썩습니다.  ",
      "- 과일의 미라화.",
    ],
  },
  "800090": {
    pathogen: "기타",
    description: [
      "- 잎에는 수없이 많고 미세한 회색 또는 은색 반점이 나타난다.",
      "- 공격받은 조직은 보통 멀리서 보면 은빛으로 보인다.",
      "- 심한 감염은 잎이 일찍 떨어지고, 나뭇가지가 뒤로 빠지며, 과일의 질이 떨어지고, 나무의 활력이 저하된다.",
      "- 좋은 물 공급은 이 해충에 의한 발생과 피해를 감소시킨다.",
    ],
  },
  "800102": {
    pathogen: "기타",
    description: [
      "- 특징적인 괴사성 가늘고 수직인 흉터가 열매 줄기에서 흘러내린다. 상처는 열매의 전체 길이에서 꽃 끝까지 닿을 수 있다.",
      "- 과일 세트 중 저온과 높은 습도로 인해 발생하는 질환입니다.",
    ],
  },
  "800103": {
    pathogen: "기타",
    description: ["- 잎이 변형되고 변색됩니다. ", "- 잎이 부서지기 쉬워집니다."],
  },
  "800107": {
    pathogen: "기타",
    description: ["- 녹색 색소가 불그스름하게 보입니다. ", "- 상태는 무해하며 소수의 식물에 영향을 미칩니다."],
  },
  "800109": {
    pathogen: "기타",
    description: ["- 잎이 변색되고 변형된다. ", "- 잎 끝이 괴사한다."],
  },
  "800110": {
    pathogen: "기타",
    description: ["검색안됨"],
  },
  "800112": {
    pathogen: "기타",
    description: ["검색안됨"],
  },
  "900008": {
    pathogen: "건강",
    description: [
      "- 과일의 표면에 오한상, 껍질 변형, 과립, 붓기, 과일 균열 등이 나타난다.",
      "- 균열의 종류 : 원형, 미세, 깊은 균열 등 3가지",
    ],
  },
  "900013": {
    pathogen: "건강",
    description: [
      "- 잎맥 사이에 노란색 조직 반점이 나타난다. ",
      "- 후기의 잎 가장자리가 노랗게 변합니다. ",
      "- 잎맥은 녹색으로 남습니다. ",
      "- 외관이 표백됩니다.",
    ],
  },
  "900015": {
    pathogen: "건강",
    description: ["- 꽃 끝 열매가 균열이 나거나 흉터, 심한 기형으로 나타납니다."],
  },
  "900016": {
    pathogen: "건강",
    description: [
      "- 과일 표면탄력에 무리를 주면 과일에 균열이 생긴다.",
      "- 줄기 주변에도 균열 및 분열이 발생할 수 있습니다.",
    ],
  },
  "900018": {
    pathogen: "건강",
    description: [
      "- 잎 여백이 먼저 빨갛게 변하고, 나중에는 잎 전체가 변색된다.",
      "- 줄기의 시들고 붉어지는 것.",
      "- 잎과 열매의 탈락.",
      "- 성장 저해.",
    ],
  },
  "900019": {
    pathogen: "건강",
    description: ["- 잎이 시들고 변색됩니다. ", "- 잎이 조기 탈락합니다."],
  },
  "900027": {
    pathogen: "건강",
    description: ["- 잎이 변형되고 변색됩니다. ", "- 줄기와 꽃잎이 꼬이고 말립니다. ", "- 마녀손가락 처럼 변형됩니다."],
  },
  "900033": {
    pathogen: "건강",
    description: ["- 물에 적은 나뭇잎 반점이 나타납니다. ", "- 잎이 시들고 갈색이 됩니다."],
  },
  "900034": {
    pathogen: "건강",
    description: ["- 잎에 연한 녹색에서 흰색 또는 노란색으로 가로 반점이 나타난다."],
  },
  "900037": {
    pathogen: "건강",
    description: ["식물이 아닌 것으로 보입니다."],
  },
  "999999": {
    pathogen: "건강",
    description: ["건강한 상태로 보입니다."],
  },
};
